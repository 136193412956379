import React from 'react';
import ReactHtmlParser, { processNodes } from 'react-html-parser';

interface Props {
    html: string;
}

let keyIndex = 0;

const ParsedUhfContent: React.FC<Props> = ({ html }) => {
    const nodeTransform = (node: any): any => {
        keyIndex++;
        if (node.type === 'tag' && node.name === 'div' && node.attribs.id === 'meControl') {
            return null;
        }
        if (
            node.type === 'tag' &&
            node.name === 'div' &&
            node.attribs.class === 'msame_Header_name st_msame_placeholder'
        ) {
            return null;
        }
        if (node.type === 'tag' && node.name === 'form' && node.attribs.class === 'c-search') {
            return null;
        }
        if (node.type === 'tag' && node.name === 'button' && node.attribs.id === 'search') {
            return null;
        }
        if (node.type === 'tag' && node.name === 'a' && node.attribs.id === 'nav-conversation') {
            return null;
        }
        if (node.type === 'tag' && node.name === 'a' && node.attribs.id === 'nav-education') {
            return null;
        }
        if (node.type === 'tag' && node.name === 'input' && node.attribs.id === 'cli_shellHeaderSearchInput') {
            return null;
        }
        if (node.type === 'tag' && node.name === 'a' && node.attribs.href === 'javascript:void(0)') {
            node.attribs.href = '#';
        }
        if (node.type === 'tag' && node.name === 'svg') {
            if (node.attribs.viewbox !== undefined || node.attribs['xml:space'] !== undefined) {
                const newAttribs = { ...node.attribs };

                if (newAttribs.viewbox !== undefined) {
                    newAttribs.viewBox = newAttribs.viewbox;
                    delete newAttribs.viewbox;
                }

                if (newAttribs['xml:space'] !== undefined) {
                    newAttribs.xmlSpace = newAttribs['xml:space'];
                    delete newAttribs['xml:space'];
                }

                const children = processNodes(node.children, nodeTransform);
                newAttribs.key = `parsed-svg-${keyIndex}`;

                return React.createElement(node.name, newAttribs, children);
            }
        }
    };

    return <>{ReactHtmlParser(html, { transform: nodeTransform })}</>;
};

export default ParsedUhfContent;
