// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class UxUtils {
    // TODO: NC: review the colors here and make sure they work with white text
    static getColorForUsername(username: string): string {
        const colors = ['#750B1C', '#DA3B01', '#FCE100',
            '#0B6A0B', '#005B70', '#0078D4', '#8764B8', '#9B0062',
            '#69797E', '#A4262C', '#8E562E', '#986F0B', '#498205',
            '#038387', '#4F6BED', '#8378DE', '#E3008C', '#A0AeB2', '#D13438',
            '#CA5010', '#C19C00', '#00AD56', '#00B7C3', '#5C2E91', '#881798', '#393939',
            '#603D30', '#FFAA44', '#8CBD18', '#005E50', '#004E8C', '#5C2E91', '#C239B3', '#7A7574'];

        let hash = 0;
        for (let i = 0; i < username.length; i++) {
            hash = 31 * hash + username.charCodeAt(i);
        }
        return colors[Math.abs(hash) % colors.length];
    }

    static readonly secondsPerHour = 3600;
    static readonly secondsPerMinute = 60;
    static formattedTimeDuration(duration: number): string {
        const hours = Math.floor(duration / this.secondsPerHour);
        let remainingSeconds = duration % this.secondsPerHour;
        const minutes = Math.floor(remainingSeconds / this.secondsPerMinute);
        remainingSeconds = remainingSeconds % this.secondsPerMinute;

        let durationString = '';
        if (hours > 0) {
            if (hours < 10) {
                durationString += '0';
            }
            durationString += `${hours}`;
            durationString += ':'
        }

        if (minutes > 0) {
            if (minutes < 10) {
                durationString += '0';
            }
            durationString += `${minutes}`;
        } else {
            durationString += '00';
        }
        durationString += ':';

        if (remainingSeconds < 10) {
            durationString += '0';
        }
        durationString += `${remainingSeconds}`;

        return durationString;
    }
}
