import baseService from './baseService';
import { API_ROUTES } from '../types/constants';
import { ApplicationState } from '../../store';
import { TtsAction, TtsAudio } from '../../store/Tts';
import { TtsResponse } from '../types/common';
import { Message } from '../../store/Conversation';
// import { eventService } from './eventService';

export interface ITtsService {
    getTts: (
        message: Message,
        voice: string,
        locale: string,
        dispatch: (action: TtsAction) => void,
        getState: () => ApplicationState) => void;

    stopTtsPlayback: () => void;
}

class TtsService implements ITtsService {
    ttsTracks: TtsAudio[] = [];
    audioSource: AudioBufferSourceNode | undefined;
    isPlayingTts: boolean = false;

    public stopTtsPlayback(): void {
        if (!this.isPlayingTts || this.audioSource == null) { return }

        this.audioSource.stop();
        this.isPlayingTts = false;
    }

    public async getTts(message: Message, voice: string, locale: string, dispatch: (action: TtsAction) => void, getState: () => ApplicationState): Promise<void> {
        const response = await baseService.get<TtsResponse>(
            API_ROUTES.TTS,
            {
                params:
                {
                    id: message.id,
                    text: message.targetText,
                    voice,
                    locale
                }
            }
        );

        const ctx = this.getAudioContext();
        const buffer = Uint8Array.from(atob(response.data.audioData), c => c.charCodeAt(0)).buffer;
        void ctx.decodeAudioData(
            buffer,
            (data: AudioBuffer) => {
                this.ttsTracks.push({ id: message.id, audioData: data });
                this.playNextTrack(ctx);
            },
            () => {
                // eventService.trackError()
            }
        );
    }

    playNextTrack(ctx: AudioContext): void {
        if (this.ttsTracks.length === 0 || this.isPlayingTts) { return }

        const ttsAudio = this.ttsTracks.shift();
        if (ttsAudio == null || ttsAudio == null) { return }

        this.audioSource = ctx.createBufferSource();
        this.audioSource.buffer = ttsAudio.audioData;
        this.audioSource.connect(ctx.destination);

        this.audioSource.addEventListener(
            'ended',
            () => {
                this.isPlayingTts = false;
                this.playNextTrack(ctx);
            }
        );

        this.isPlayingTts = true;
        this.audioSource.start();
    }

    getAudioContext(): AudioContext {
        // https://developer.mozilla.org/en-US/docs/Web/API/AudioContext
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        const AudioContext = (window as any).AudioContext || // our preferred impl
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            (window as any).webkitAudioContext || // fallback, mostly when on Safari
            false; // could not find.

        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, no-extra-boolean-cast
        if (!!AudioContext) {
            return new AudioContext();
        } else {
            throw new Error('Browser does not support Web Audio API (AudioContext is not available).');
        }
    }
}

export const ttsService = new TtsService();
