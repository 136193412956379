import * as Languages from './Languages';
import * as Conversation from './Conversation';
import * as Tts from './Tts';
import * as Version from './Version';
import * as Uhf from './Uhf';
import * as Validation from './Validation';

// The top-level state object
export interface ApplicationState {
    validation: Validation.ValidationState | undefined;
    languages: Languages.LanguagesState | undefined;
    conversation: Conversation.ConversationState | undefined;
    tts: Tts.TtsState | undefined;
    version: Version.VersionState | undefined;
    uhf: Uhf.UhfState | undefined;
}

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export type AppThunkAction<TAction> = (dispatch: (action: TAction) => void, getState: () => ApplicationState) => void;

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    validation: Validation.reducer,
    languages: Languages.reducer,
    conversation: Conversation.reducer,
    tts: Tts.reducer,
    version: Version.reducer,
    uhf: Uhf.reducer
};
