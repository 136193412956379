import { API_ROUTES } from '../types/constants';
import baseService from './baseService';
import { logService } from './logService';

export interface IEventService {
    trackPageView: (name: any) => void;
    trackEvent: (name: any, props: any, logOnlyProps: any) => void;
    trackError: (name: string, error: Error, properties: any) => void;
}

class EventService implements IEventService {
    public async trackPageView(name: any): Promise<void> {
        this.sendEvent(name, {});
    }

    // json must be a valid json obj
    public trackEvent(qualifiedName: string, props: any | undefined = undefined, logOnlyProps: any | undefined = undefined): void {
        const pieces = qualifiedName.split('.');
        const eventType = pieces[0];
        const eventName = pieces[1];
        if (props == null) {
            props = { eventName };
        } else {
            props.eventName = eventName;
        }

        this.sendEvent(eventType, props, logOnlyProps);
    }

    public trackError(qualifiedName: string, error: any): void {
        if (error === undefined) {
            return;
        }

        const pieces = qualifiedName.split('.');
        const eventType = pieces[0];
        const eventName = pieces[1];

        if (error instanceof Error) {
            // if it's an unexpected error log it as an exception
            try {
                this.sendEvent(
                    eventType,
                    {
                        eventName,
                        errorName: error.name,
                        errorDetails: error.message
                    }
                );
            } catch (e) {
                //
            }
        } else {
            // if it's not a js error log it as an error event
            this.sendEvent(
                eventType,
                {
                    eventName,
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    errorDetails: `${error}`
                }
            );
        }
    }

    // must be a json obj
    private sendEvent(eventType: string, props: any | undefined, logOnlyProps: any | undefined = undefined): void {
        try {
            void baseService.post(
                API_ROUTES.EVENT,
                {
                    EventType: eventType,
                    Properties: props ?? {}
                });
        } catch (e) {
            // props.error = e;
            // logService.trackEvent(eventType, props, logOnlyProps);
        }

        logService.trackEvent(eventType, props, logOnlyProps);
    }
}

export const eventService = new EventService();
