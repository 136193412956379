import { localize } from '../common/types/common';
import { Message, MessageType } from '../store/Conversation';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class TranscriptCreator {
    static getTranscript(roomCode: string, myParticipantId: string, messages: Message[]): string {
        if (roomCode == null) {
            return 'No data';
        }

        let result: string = '==================================' + TranscriptCreator.newLine();
        result += localize('TRANSCRIPT') + ' ' + roomCode + TranscriptCreator.newLine(2);
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        result += localize('DATE') + this.newLine() + new Date() + TranscriptCreator.newLine(2);
        result += '==================================' + TranscriptCreator.newLine();
        result += localize('CONVERSATION') + ' ' + roomCode + this.newLine(2)

        messages.forEach((message: Message) => {
            switch (message.type) {
                case MessageType.ParticipantJoined:
                case MessageType.ParticipantLeft:
                    result += `${message.participantName} @ ${message.timestamp}` + TranscriptCreator.newLine();
                    result += this.tab() + message.message;
                    break;
                case MessageType.Final:
                case MessageType.Partial:
                    result += `${message.participantName} @ ${message.timestamp}` + TranscriptCreator.newLine();
                    if (message.sourceText != null) {
                        result += this.tab() + `Recognized in ${message.sourceLanguage}: ${message.sourceText}` + TranscriptCreator.newLine();
                    }

                    if (message.participantId !== myParticipantId) {
                        result += `\tTranslation in ${message.targetLanguage}: ${message.targetText}`;
                    }
                    break;
            }

            result += TranscriptCreator.newLine(2);
        });

        return result;
    }

    private static tab(count: number = 1): string {
        let tabString = '';
        for (let i = 0; i < count; i++) {
            tabString += '\t';
        }
        return tabString;
    }

    private static newLine(count: number = 1): string {
        let newLineString = '';
        for (let i = 0; i < count; i++) {
            newLineString += '\n';
        }
        return newLineString;
    }
}
