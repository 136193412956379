import FileSaver from 'file-saver';
import * as spx from 'microsoft-cognitiveservices-speech-sdk';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as rs from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import '../common/design/Conversation.css';
import { eventService } from '../common/services/eventService';
import { logService } from '../common/services/logService';
import { AppsLanguage, getForcedColorsState, localize, sort, TKLocale, TKTranslationVoice } from '../common/types/common';
import { EVENT_KEYS, KEYS, noop, STORAGE, UNLOCALIZED_STRINGS } from '../common/types/constants';
import { IConversationDelegate } from '../common/types/conversationDelegate';
import * as ApplicationState from '../store';
import * as Conversation from '../store/Conversation';
import { Message, MessageType } from '../store/Conversation';
import * as Languages from '../store/Languages';
import * as Tts from '../store/Tts';
import * as Validation from '../store/Validation';
import { ConversationUtils } from '../utils/conversationUtils';
import TranscriptCreator from '../utils/transcriptCreator';
import { UxUtils } from '../utils/uxUtils';

// icons
import exit_icon from '../common/icons/exit.svg';
import exit_icon_fc from '../common/icons/exit_forced_colors.svg';
import gear_icon from '../common/icons/gear.svg';
import gear_icon_fc from '../common/icons/gear_forced_colors.svg';
import help_icon from '../common/icons/helpIcon.svg';
import help_icon_fc from '../common/icons/helpIcon_forced_colors.svg';
import participants_icon from '../common/icons/Participants.svg';
import participants_icon_fc from '../common/icons/Participants_forced_colors.svg';
import logo_icon from '../common/images/translator-logo.png';

type LanguageProps =
    Languages.LanguagesState
    & Conversation.ConversationState
    & Tts.TtsState
    & typeof Validation.actionCreators
    & typeof Languages.actionCreators
    & typeof Conversation.actionCreators
    & typeof Tts.actionCreators
    & RouteComponentProps<{ startDateIndex: string }>;

class ConversationComponent extends React.Component<LanguageProps> implements IConversationDelegate {
    private userDidLeave: boolean = false;
    private isSidebarOpen: boolean = false;
    private focusedElPriorToSidebar: HTMLElement | null = null;
    private abortController: AbortController | null = null;

    private lastFinalId: string | null = null;

    public componentDidMount(): void {
        eventService.trackEvent(EVENT_KEYS.PAGE.CONVERSATION_PAGE_LOADED);
        if (this.props.conversationService == null) {
            this.leave();
            return;
        }
        this.props.conversationService.setConversationDelegate(this);
        this.initializeConversationTimer();
        this.scrollToBottom();
    }

    public componentWillUnmount(): void {
        this.leave();
    }

    public componentDidUpdate(): void {
        this.sidebarFocusTrap();
        this.scrollToBottom();
    }

    private sidebarFocusTrap(): void {
        if (this.isSidebarOpen === this.state.isSidebarOpen) { return; }

        const sidebar = document.querySelector('.sidebar');
        const focusableNodes = sidebar?.querySelectorAll('#sidebarHeader, #sidebarFocusable, #showPartialsSwitch, #autoTtsSwitch');
        const focusable = Array.prototype.slice.call(focusableNodes);

        if (!this.isSidebarOpen && this.state.isSidebarOpen) { // AJN: first render post sidebar opening
            this.focusedElPriorToSidebar = document.activeElement as HTMLElement;

            if (focusable != null) {
                // focus header
                const header = sidebar?.querySelector('#sidebarHeader') as HTMLElement;
                header?.focus();
                this.abortController = new AbortController();

                // bind kbd events
                sidebar?.addEventListener(
                    'keydown',
                    (event) => { this.onKeyDown(event, focusable) },
                    { signal: this.abortController.signal }
                );
            }
        } else if (this.isSidebarOpen && !this.state.isSidebarOpen) { // AJN: first render post sidebar closing
            // unbind kbd events to avoid dupe call on next open
            this.abortController?.abort();
            this.abortController = null;

            // reset focus to original element
            this.focusedElPriorToSidebar?.focus();
        }

        // ensure private component state matches async state vars
        this.isSidebarOpen = this.state.isSidebarOpen;
    }

    private onKeyDown(event: Event, focusable: any[]): void {
        const kbdEvent = event as KeyboardEvent;
        const current = document.activeElement;
        const indexOfCurrent = focusable.indexOf(current);
        if (kbdEvent.keyCode === KEYS.ESCAPE) {
            kbdEvent.preventDefault();
            this.closeSidebar();
        } else if (indexOfCurrent !== -1 && kbdEvent.keyCode === KEYS.TAB) {
            let nextIndex = (indexOfCurrent + (kbdEvent.shiftKey ? -1 : 1)) % focusable.length;
            if (nextIndex < 0) {
                nextIndex = focusable.length - 1;
            }
            const nextElement = focusable[nextIndex];
            kbdEvent.preventDefault();
            nextElement.focus();
        } else if (kbdEvent.keyCode === KEYS.ENTER) {
            if (current?.id === 'showPartialsSwitch') {
                kbdEvent.preventDefault();
                this.props.toggleShowPartials();
            } else if (current?.id === 'autoTtsSwitch') {
                kbdEvent.preventDefault();
                this.props.toggleAutoTts();
            }
        }
    }

    private initializeConversationTimer(): void {
        const intervalId = setInterval(
            () => { this.setState({ duration: this.state.duration + 1 }) },
            1000);

        this.setState({ durationIntervalId: intervalId })
    }

    private scrollToBottom(): void {
        // no-op if we haven't received a new messsage
        if (this.state.mostRecentMessageId === this.props.mostRecentMessageId) { return }

        this.setState({ mostRecentMessageId: this.props.mostRecentMessageId });
        const messageList = document.querySelector('.messageList');
        const lastMessage = messageList?.lastElementChild as HTMLLIElement;

        if (lastMessage != null) {
            lastMessage.scrollIntoView({ behavior: 'smooth' });
        }
    }

    render(): React.ReactElement {
        const forcedColorsIsSupportedAndEnabled: boolean = getForcedColorsState();
        const downloadId = 'downloadId'
        const downloadTag = 'span'
        return (
            <React.Fragment>
                <header>
                    <rs.Navbar className="navbar navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 green-bg">
                        <rs.NavbarBrand className="customBrand"> <img src={logo_icon} alt={UNLOCALIZED_STRINGS.PRODUCT_NAME} style={{
                            height: 40,
                            width: 40
                        }} />&nbsp;
                        <h1 style={{
                            all: 'inherit'
                        }} >
                            {UNLOCALIZED_STRINGS.PRODUCT_NAME}
                        </h1>
                        </rs.NavbarBrand>
                        <rs.Container>
                            <ul className="navbar-nav flex-grow">
                                <li className="navbar-item">{UxUtils.formattedTimeDuration(this.state.duration)}</li>
                                <li className="navbar-item">{this.props.roomCode.toUpperCase()}</li>
                                <li className="navbar-item">
                                    <rs.Button
                                        className="navbarButton"
                                        aria-label={localize('SETTINGS')}
                                        onClick={() => { this.toggleSettings(); }}>
                                        <img src={forcedColorsIsSupportedAndEnabled ? gear_icon_fc : gear_icon} alt=''/>
                                    </rs.Button>
                                </li>
                                <li className="navbar-item">
                                    <rs.Button
                                        className="navbarButton"
                                        aria-label={localize('ARIA_SHOW_PARTICIPANTS') + ', ' + `${this.getAllParticipants().length}`}
                                        onClick={
                                            (e) => {
                                                e.preventDefault();
                                                this.toggleParticipants();
                                            }
                                        }>
                                        <div className="participantIconContainer">
                                            <img src={forcedColorsIsSupportedAndEnabled ? participants_icon_fc : participants_icon} alt=''/>
                                            <div
                                                aria-label={`${this.getAllParticipants().length}`}
                                                className="participantCountIcon">
                                                {this.getAllParticipants().length}
                                            </div>
                                        </div>
                                    </rs.Button>
                                </li>
                                <li className="navbar-item">
                                    <rs.Button
                                        className="navbarButton"
                                        aria-label={localize('DOWNLOAD_LOGS_TITLE')}
                                        onClick={
                                            (e) => {
                                                e.preventDefault();
                                                this.toggleSaveLogFileModal();
                                            }
                                        }>
                                        <img src={forcedColorsIsSupportedAndEnabled ? help_icon_fc : help_icon} alt=''/>
                                    </rs.Button>
                                </li>
                                <li className="navbar-item exitConversation">
                                    <rs.Button
                                        className="navbarButton"
                                        aria-label={localize('ARIA_LEAVE_CONVERSATION')}
                                        onClick={
                                            (e) => {
                                                e.preventDefault();
                                                this.toggleLeaveModal();
                                            }
                                        }>
                                        <img src={forcedColorsIsSupportedAndEnabled ? exit_icon_fc : exit_icon} alt=''/>
                                    </rs.Button>
                                </li>
                            </ul>
                        </rs.Container>
                    </rs.Navbar>
                </header>
                <rs.Modal
                    isOpen={this.state.saveLogFileModalOpen}
                    toggle={this.toggleSaveLogFileModal}
                    labelledBy={downloadId}
                >
                    <rs.ModalHeader
                        id={downloadId}
                        tag={downloadTag}
                        toggle={this.toggleSaveLogFileModal}
                        close={
                            <rs.Button
                                aria-label={localize('CANCEL')}
                                className={(forcedColorsIsSupportedAndEnabled ? 'exitSidebarButtonFC' : 'exitSidebarButtonRegular') + ' exitSidebarButton'}
                                onClick={() => { this.toggleSaveLogFileModal() }}
                                outline
                            />
                        }>{localize('DOWNLOAD_LOGS_TITLE')}</rs.ModalHeader>
                    <rs.ModalBody className="modalBody" >{localize('DOWNLOAD_LOGS_PROMPT')}</rs.ModalBody>
                    <rs.ModalFooter>
                        <rs.Button
                            className="modalButton"
                            color="primary"
                            onClick={() => {
                                logService.saveLogFile();
                                this.toggleSaveLogFileModal();
                            }}>
                            {localize('YES')}
                        </rs.Button>
                        < rs.Button
                            className="modalButton"
                            color="secondary"
                            onClick={() => { this.toggleSaveLogFileModal(); }}>
                            {localize('NO')}
                        </rs.Button>
                    </rs.ModalFooter>
                </rs.Modal>
                <rs.Modal
                    isOpen={this.state.leaveModal}
                    toggle={!this.state.conversationCancelled ? this.toggleLeaveModal : undefined}
                >
                    <rs.ModalHeader
                        className="modalHeader"
                        toggle={!this.state.conversationCancelled ? this.toggleLeaveModal : undefined}
                        aria-label={localize('LEAVE_CONFIRMATION')}
                        close={ !this.state.conversationCancelled
                            ? <rs.Button
                                aria-label={localize('CANCEL')}
                                className={(forcedColorsIsSupportedAndEnabled ? 'exitSidebarButtonFC' : 'exitSidebarButtonRegular') + ' exitSidebarButton'}
                                onClick={() => { this.toggleLeaveModal() }}
                                outline
                            />
                            : undefined
                        }>
                        {localize('LEAVE_CONFIRMATION')}
                    </rs.ModalHeader>
                    <rs.ModalBody className="modalBody" tabIndex={0} aria-label={localize('LEAVE_CONFIRMATION_MESSAGE')}>
                        {(this.state.conversationCancelled ? localize('CONVERSATION_CANCELLED_MODAL') : localize('LEAVE_CONFIRMATION_MESSAGE')) + ' '}
                        <br></br>
                        <br></br>
                        <a
                            className="downloadTranscriptLink"
                            onClick={(e) => {
                                e.preventDefault();
                                this.downloadTranscript();
                            }}
                            href={localize('DOWNLOAD_TRANSCRIPT')}
                            aria-label={localize('DOWNLOAD_TRANSCRIPT')}>
                            <u>{localize('DOWNLOAD_TRANSCRIPT')}</u>
                        </a></rs.ModalBody>
                    <rs.ModalFooter>
                        <rs.Button
                            className="modalButton"
                            color="primary"
                            onClick={() => {
                                this.leave();
                            }}
                            aria-label={localize('LEAVE')}
                        >{localize('LEAVE')}</rs.Button>{' '}
                        {!this.state.conversationCancelled &&
                            <rs.Button
                                className="modalButton"
                                color="danger"
                                aria-label={localize('CANCEL')}
                                onClick={() => { this.toggleLeaveModal() }}>
                                {localize('CANCEL')}
                            </rs.Button>}
                    </rs.ModalFooter>
                </rs.Modal>
                <rs.Modal
                    isOpen={this.state.alertModalOpen}
                    toggle={this.toggleAlertModal}
                >
                    <rs.ModalHeader
                        className="modalHeader"
                        aria-label={localize('MIC_PERMISSIONS_DISABLED_HEADER')}
                        toggle={this.toggleAlertModal}
                        close={
                            <rs.Button
                                aria-label={localize('CANCEL')}
                                className={(forcedColorsIsSupportedAndEnabled ? 'exitSidebarButtonFC' : 'exitSidebarButtonRegular') + ' exitSidebarButton'}
                                onClick={() => { this.toggleAlertModal() }}
                                outline
                            />
                        }>{localize('MIC_PERMISSIONS_DISABLED_HEADER')}</rs.ModalHeader>
                    <rs.ModalBody className="modalBody" tabIndex={0} aria-label={localize('MIC_PERMISSIONS_DISABLED')} >{localize('MIC_PERMISSIONS_DISABLED')}</rs.ModalBody>
                </rs.Modal>
                <main>
                    <rs.Container className="chatWrapper">
                        <rs.Card className="my-2">
                            <rs.CardBody className="chatBox">
                                <rs.Label aria-label={localize('WELCOME_CONVERSATION')} for="messages">{localize('WELCOME_CONVERSATION')}</rs.Label>
                                {this.addMessages()}
                            </rs.CardBody>
                        </rs.Card>
                        <rs.Form className="sendMessage">
                            <div className="sendMessageInput">
                                <rs.Input
                                    className="sendMessageTextBox"
                                    disabled={this.isMuted()}
                                    aria-label={localize('ENTER_TEXT')}
                                    onChange={(e) => { this.setTextMessageState(e.target.value); }}
                                    onKeyPress={
                                        (e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                this.sendMessage(this.state.textMessageValue);
                                                this.resetTextMessageState();
                                            }
                                        }
                                    }
                                    type="text"
                                    value={this.state.textMessageValue}
                                    placeholder={localize('ENTER_TEXT')}
                                />
                            </div>
                            <div className="sendIcons">
                                <rs.Button
                                    disabled={this.isMuted()}
                                    aria-label={localize('ARIA_SEND_MESSAGE')}
                                    className={(forcedColorsIsSupportedAndEnabled ? 'sendMessageIconFC' : 'sendMessageIconRegular') + ' sendMessageIcon'}
                                    onClick={
                                        (e) => {
                                            e.preventDefault();
                                            this.sendMessage(this.state.textMessageValue);
                                            this.resetTextMessageState();
                                        }
                                    }
                                >
                                </rs.Button>
                                <rs.Button
                                    aria-label={this.state.isMicActive ? localize('ARIA_MIC_ON') : localize('ARIA_MIC_OFF')}
                                    disabled={this.isMuted() || ((this.getCurrentLocale()?.features.speech) === false)}
                                    className={this.getMicCssClassName(this.state.isMicActive, forcedColorsIsSupportedAndEnabled)}
                                    onClick={
                                        (e) => {
                                            e.preventDefault();
                                            // TODO:AJN: enable mic
                                            if (!this.state.isMicActive) {
                                                this.enableMicWithPermission();
                                            } else {
                                                this.disableMic();
                                            }
                                        }
                                    }>
                                </rs.Button>
                            </div>
                        </rs.Form>
                    </rs.Container>
                </main>
                <div aria-live='polite' tabIndex={-1} aria-label={this.getSidebarAriaAnnouncement()}></div>
                <div className={`sidebar ${this.state.isSidebarOpen ? 'sidebar-open' : 'noop'}`}>
                    {this.state.isSidebarOpen &&
                        <div>
                            <rs.Button
                                tabIndex={-1}
                                id="sidebarFocusable"
                                aria-label={this.state.isSettingsOpen ? localize('ARIA_CLOSE_SETTINGS') : localize('ARIA_CLOSE_PARTICIPANTS_VIEW')}
                                className={(forcedColorsIsSupportedAndEnabled ? 'exitSidebarButtonFC' : 'exitSidebarButtonRegular') + ' exitSidebarButton'}
                                onClick={() => { this.closeSidebar(); }}
                                outline
                            />
                            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                            <h1 tabIndex={-1} aria-label={this.getSidebarTitle()} id="sidebarHeader" className="sidebar-header">{this.getSidebarTitle()}</h1>
                            <div className="scrollable-settings">
                                {this.state.isSettingsOpen && this.addSettings()}
                                {this.state.isParticipantsOpen && this.showParticipants()}
                            </div>
                        </div>
                    }
                </div>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
                {this.state.isSidebarOpen && (<div
                    className="sidebar-overlay-open"
                    onClick={() => { this.closeSidebar(); }}></div>)}
                {!this.state.isSidebarOpen && (<div className="sidebar-overlay-closed"></div>)}
            </React.Fragment>
        );
    }

    private getMicCssClassName(isMicActive: boolean, supportsForcedColorsAndActive: boolean): string {
        let className = '';
        if (isMicActive) {
            className += 'micButtonOn ';
            className += supportsForcedColorsAndActive ? 'micButtonOnFC' : 'micButtonOnRegular';
        } else {
            className += 'micButtonOff ';
            className += supportsForcedColorsAndActive ? 'micButtonOffFC' : 'micButtonOffRegular';
        }

        return className;
    }

    private enableMicWithPermission(): void {
        const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: false })
        permissions
            .then((stream) => {
                this.enableMic();
            })
            .catch(() => {
                this.toggleAlertModal();
            });
    }

    private getSidebarAriaAnnouncement(): string {
        if (this.state.isSettingsOpen) {
            return localize('ARIA_SETTINGS_MENU_OPEN');
        } else if (this.state.isParticipantsOpen) {
            return localize('ARIA_PARTICIPANTS_MENU_OPEN');
        } else {
            return localize('ARIA_MENU_CLOSED');
        }
    }

    private selectedLanguageCode(): string { return localStorage.getItem(STORAGE.LANGUAGE_CODE) ?? this.props.selectedLanguageCode }
    private selectedLocaleCode(): string { return localStorage.getItem(STORAGE.LOCALE_CODE) ?? this.props.selectedLocaleCode }
    private selectedSpeechRegionCode(): string | undefined { return localStorage.getItem(STORAGE.SPEECH_REGION_CODE) ?? this.props.selectedSpeechRegionCode }
    private selectedUsername(): string { return localStorage.getItem(STORAGE.USERNAME) ?? this.props.username }

    private getSidebarTitle(): string {
        if (this.state.isSettingsOpen) {
            return localize('SETTINGS');
        } else if (this.state.isParticipantsOpen) {
            return localize('PARTICIPANTS');
        } else {
            return '';
        }
    }

    private addMessages(): React.ReactElement {
        return (
            <ul aria-live={this.props.autoTts ? 'off' : 'polite'} className="messageList">
                {
                    this.getAllMessages().map((message: Message) => (
                        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                        this.messageFromMessageType(message)
                    ))
                }
            </ul>
        );
    }

    private messageFromMessageType(message: Message): React.ReactElement {
        switch (message.type) {
            case MessageType.ParticipantJoined:
            case MessageType.ParticipantLeft:
                return (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                    <li tabIndex={0} id='message' aria-label={this.messageAriaLabel(message)} key={message.id}>
                        <p className="username">{message.message}</p>
                    </li>
                );
            case MessageType.Final:
            case MessageType.Partial:
                return (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex, jsx-a11y/aria-props
                    <li tabIndex={0} id='message' aria-hidden={message.type === MessageType.Partial} aria-label={this.messageAriaLabel(message)} aria-description={this.messageAriaLabel(message)} key={message.id}>
                        <div aria-hidden='true' className="messageContainer">
                            <div aria-hidden='true' className="userIconContainer">
                                {this.renderUserIcon(this.participantNameForId(message.participantId))}
                            </div>
                            <div
                                aria-hidden='true'
                                className="messageContent">
                                <p className="username">{this.participantNameForId(message.participantId)}</p>
                                <div className="messageText">{message.targetText}</div>
                                {this.participantNameForId(message.participantId) !== this.props.username && message.sourceText != null && (
                                    <div aria-hidden='true' className="sourceTextSubtext">{message.sourceText}</div>
                                )}
                            </div>
                        </div>
                    </li>
                );
        }
    }

    private messageAriaLabel(message: Message): string {
        switch (message.type) {
            case MessageType.Partial:
            case MessageType.Final:
                return `${localize('ARIA_MESSAGE_FROM')}${this.participantNameForId(message.participantId)}. ${message.targetText}`;
            case MessageType.ParticipantJoined:
                return `${this.participantNameForId(message.participantId)} ${localize('ARIA_PARTICIPANT_JOINED')}`;
            case MessageType.ParticipantLeft:
                return `${this.participantNameForId(message.participantId)} ${localize('ARIA_PARTICIPANT_LEFT')}`;
            default:
                return 'error';
        }
    }

    private addSettings(): React.ReactElement {
        const voices = this.getSortedVoices();
        if (voices == null || voices.length === 0) {
            return (
                <div className="sidebar-settings">
                    {this.addShowPartialsSetting()}
                </div>
            );
        }

        return (
            <table>
                <tbody>
                    {this.addShowPartialsSetting()}
                    {this.addAutoTtsSetting()}
                    {this.addVoiceSelectionSetting(voices)}
                </tbody>
            </table>
        );
    }

    private addShowPartialsSetting(): React.ReactElement {
        return (
            <tr className="setting-row" onClick={() => this.props.toggleShowPartials()} >
                <td>
                    <div className="setting-wrapper">
                        <span className="setting-name">{localize('SHOW_PARTIALS_SETTING')}</span>
                        <rs.Form>
                            <rs.FormGroup switch>
                                <rs.Input
                                    id="showPartialsSwitch"
                                    tabIndex={-1}
                                    className="settingsToggle"
                                    aria-label={localize('ARIA_TOGGLE_SHOW_PARTIALS')}
                                    type="switch"
                                    checked={this.props.showPartials}
                                    onChange={() => { noop() }}
                                />
                            </rs.FormGroup>
                        </rs.Form>
                    </div>
                </td>
            </tr>
        );
    }

    private addAutoTtsSetting(): React.ReactElement {
        return (
            <tr className="setting-row" onClick={() => this.props.toggleAutoTts()} >
                <td>
                    <div className="setting-wrapper">
                        <span className="setting-name">{localize('AUTO_TTS_SETTING')}</span>
                        <rs.Form>
                            <rs.FormGroup switch>
                                <rs.Input
                                    id="autoTtsSwitch"
                                    tabIndex={-1}
                                    className="settingsToggle"
                                    aria-label={localize('AUTO_TTS_SETTING')}
                                    type="switch"
                                    checked={this.props.autoTts}
                                    onChange={() => { noop() }}
                                />
                            </rs.FormGroup>
                        </rs.Form>
                    </div>
                </td>
            </tr>
        );
    }

    private addVoiceSelectionSetting(voices: TKTranslationVoice[]): React.ReactElement {
        return (
            <tr>
                <td>
                    <div className="setting-wrapper">
                        <span aria-label={localize('TEXT_TO_SPEECH_VOICE')} className="setting-name">{localize('TEXT_TO_SPEECH_VOICE')}</span>
                        <div className="voice-selector">
                            <rs.Form>
                                <rs.FormGroup>
                                    <rs.Input
                                        className="voiceSelectionInput"
                                        id="sidebarFocusable"
                                        aria-label={localize('ARIA_SELECT_VOICE')}
                                        type="select"
                                        name={localize('VOICE')}
                                        defaultValue={this.getCurrentVoice()?.displayName}
                                        required
                                        onChange={this.onVoiceSelected}>
                                        {
                                            voices.map((voice: TKTranslationVoice) =>
                                                <option aria-label={voice.displayName} key={voice.shortName}>{voice.displayName}</option>
                                            )
                                        }
                                    </rs.Input>
                                </rs.FormGroup>
                            </rs.Form>
                        </div>
                    </div>
                </td>
            </tr>
        )
    }

    private showParticipants(): React.ReactElement {
        return (
            <div className="sidebar-table" role="list">
                {this.getAllParticipants().map((participant) => (
                    <div
                        id="sidebarFocusable"
                        role="listitem"
                        tabIndex={-1}
                        aria-label={participant.displayName}
                        key={participant.id}
                        className="participant-row"
                    >
                        <div className="sidebar-userIcon">
                            {this.renderUserIcon(participant.displayName)}
                        </div>
                        <p
                            className="participant-name">
                            {participant.displayName}
                        </p>
                    </div>
                ))}
            </div>
        )
    }

    readonly onVoiceSelected: React.ChangeEventHandler<HTMLInputElement> = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedVoiceDisplayName = event.target.value;
        const voices = this.getSortedVoices();
        if (voices == null) {
            // TODO:AJN: event error
            return;
        }

        for (const voice of voices) {
            if (voice.displayName === selectedVoiceDisplayName) {
                this.props.setVoice(voice);
                return;
            }
        }
    }

    private myParticipant(participants: spx.IParticipant[]): spx.IParticipant | undefined {
        const matchingParticipants = participants.filter((participant) => participant.id === this.props.myParticipantId);
        if (matchingParticipants == null || matchingParticipants.length !== 1) { return undefined }
        return matchingParticipants[0];
    }

    private readonly isMuted = (): boolean => {
        const myParticipant = this.myParticipant(this.props.participants);
        if (myParticipant == null) { return false } // if something goes wrong and can't find our participant, don't mute ourselves
        return myParticipant.isMuted;
    }

    public state = {
        micPermissions: false,
        conversationCancelled: false,
        userDidLeave: false,
        leaveModal: false,
        isOpen: false,
        textMessageValue: '',
        isMicActive: false,
        isParticipantsOpen: false,
        isSidebarOpen: false,
        isSettingsOpen: false,
        saveLogFileModalOpen: false,
        alertModalOpen: false,
        duration: 0,
        durationIntervalId: 0,
        mostRecentMessageId: ''
    };

    private closeSidebar(): void {
        this.setState({
            isSidebarOpen: false,
            isParticipantsOpen: false,
            isSettingsOpen: false
        });
    }

    private toggleSettings(): void {
        // - if settings is NOT open, then the side bar should be open
        // - if settings is open, then the side bar should close
        // the sidebar could already be open from the participants view, so technically it's state maps to !isSettingsOpen
        // also, turn off participants either way
        this.setState({
            isSidebarOpen: !this.state.isSettingsOpen,
            isSettingsOpen: !this.state.isSettingsOpen,
            isParticipantsOpen: false
        })
    }

    private toggleParticipants(): void {
        // - if participants is NOT open, then the side bar should be open
        // - if participants is open, then the side bar should close
        // the sidebar could already be open from the settings view, so technically it's state maps to !isParticipantsOpen
        // also, we turn off settings either way
        this.setState({
            isSidebarOpen: !this.state.isParticipantsOpen,
            isParticipantsOpen: !this.state.isParticipantsOpen,
            isSettingsOpen: false
        })
    }

    private readonly setTextMessageState = (message: string): void => {
        this.setState({ textMessageValue: message });
    }

    private readonly resetTextMessageState = (): void => {
        this.setTextMessageState('');
    }

    private enableMic(): void {
        this.props.conversationService?.startTranscribingAsync();
        this.setState({
            isMicActive: true
        });
    }

    private disableMic(): void {
        this.props.conversationService?.stopTranscribingAsync();
        this.setState({
            isMicActive: false
        });
    }

    private readonly onSessionStopped = (): void => {
        this.setState({
            conversationCancelled: true,
            leaveModal: true
        });
    }

    private readonly toggleSaveLogFileModal = (): void => {
        this.setState({
            saveLogFileModalOpen: !this.state.saveLogFileModalOpen
        })
    }

    private readonly toggleAlertModal = (): void => {
        this.setState({
            alertModalOpen: !this.state.alertModalOpen
        })
    }

    private readonly toggleLeaveModal = (): void => {
        this.setState({
            leaveModal: !this.state.leaveModal
        });
    }

    private readonly sendMessage = (message: string): void => {
        if (this.props.conversationService == null) {
            eventService.trackEvent(EVENT_KEYS.ERROR.NO_SPEECH_SERVICE_INITIALIZED);
            return
        }

        if (message === '') {
            return;
        }

        this.props.conversationService.sendTextMessage(message);
    }

    private getCurrentLanguage(): AppsLanguage | undefined {
        return this.props.languages?.get(this.selectedLanguageCode());
    }

    private getCurrentLocale(): TKLocale | undefined {
        const language = this.getCurrentLanguage();
        return language?.locales.get(this.selectedLocaleCode());
    }

    private getCurrentVoice(): TKTranslationVoice | undefined {
        let voice = this.props.voice;
        if (voice == null) {
            const voices = this.getSortedVoices();
            if (voices != null) {
                voice = voices[0];
            }
        }
        return voice;
    }

    private getSortedVoices(): TKTranslationVoice[] | undefined {
        const language = this.getCurrentLanguage();
        if ((language == null) || language.voices == null || language.voices.length === 0) {
            return undefined;
        }

        return language.voices.sort((one, two) => sort(one.displayName, two.displayName));
    }

    private getAllMessages(): Message[] {
        let messagesToShow: Message[] = [];
        if (this.props.messages != null) {
            messagesToShow = Array.from(this.props.messages);
        }

        if (this.props.activePartial != null) {
            messagesToShow.push(this.props.activePartial);
        }

        return messagesToShow;
    }

    private getAllParticipants(): spx.IParticipant[] {
        if (this.props.participants == null || this.props.participants.length === 0) {
            return [];
        }

        return this.props.participants.filter((participant: spx.IParticipant) => participant != null);
    }

    private participantNameForId(id: string): string { return ConversationUtils.participantNameForId(this.getAllParticipants(), id); }

    private renderUserIcon(username: string): React.ReactElement {
        return (
            <div className="userIcon"
                style={{
                    backgroundColor: UxUtils.getColorForUsername(username),
                    border: '0.5px solid #000'
                }}>
                { username.charAt(0).toUpperCase()}
            </div>
        );
    }

    private shouldRequestTts(message: Message): boolean {
        return this.props.autoTts && message.participantId !== this.props.myParticipantId;
    }

    private isDanglingPartial(resultId: string): boolean {
        return this.lastFinalId != null && resultId === this.lastFinalId;
    }

    cancelled(
        errorCode: spx.CancellationErrorCode,
        errorDetails: string,
        offset: number,
        reason: spx.CancellationReason,
        sessionId: string): void {
        console.log(`cancelled was invoked with: ${errorCode}, ${errorDetails}, ${reason}`);
        // AJN: cancellation is called even if we manually leave a meeting, but we shouldn't show cancellation UX in that case
        eventService.trackEvent(
            EVENT_KEYS.CONVERSATION.MEETING_CANCELLED,
            {
                errorCode: errorCode.toString(),
                errorDetails,
                offset,
                reason: reason.toString()
            },
            {
                sessionId,
                roomCode: this.props.roomCode
            }
        );
    }

    conversationExpiration(expirationTime: number, sessionId: string): void {
        eventService.trackEvent(
            EVENT_KEYS.CONVERSATION.EXPIRATION_NOTIFICATION,
            {
                expirationTime: expirationTime.toString()
            },
            {
                sessionId,
                roomCode: this.props.roomCode
            });
    }

    participantsChanged(participants: spx.IParticipant[], reason: spx.ParticipantChangedReason, sessionId: string): void {
        if (this.props.myParticipantId == null || this.props.myParticipantId === '') {
            // can actually do this by name since JS implementation doesn't allow dupe names
            const id = participants.filter((participant) => participant.displayName === this.props.username)[0].id;
            this.props.setMyParticipantId(id);
        }
        switch (reason) {
            case spx.ParticipantChangedReason.JoinedConversation:
                this.props.participantsJoined(participants);
                break;
            case spx.ParticipantChangedReason.LeftConversation:
                this.props.participantsLeft(participants);
                break;
            case spx.ParticipantChangedReason.Updated: {
                const newMe = this.myParticipant(participants);
                // if change contains my participant and I'm now going to be muted, stop transcribing and update mic icon
                // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                if (newMe != null && newMe.isMuted && this.state.isMicActive) {
                    this.disableMic();
                }
                this.props.participantsUpdated(participants);
                break;
            }
        }
    }

    textMessageReceived(offset: number, result: spx.ConversationTranslationResult, sessionId: string): void {
        const resultLocaleCode = ConversationUtils.resultLocaleCodeFor(this.props.capitoLocaleCode);
        if (resultLocaleCode == null) {
            eventService.trackEvent(
                EVENT_KEYS.ERROR.NO_CAPITO_LOCALE_CODE,
                {
                    spxMethod: 'textMessageReceived'
                },
                {
                    sessionId
                });
            return;
        }

        const targetText = this.targetTextFromResult(result, resultLocaleCode);
        if (targetText != null && targetText !== '$removed$') {
            eventService.trackEvent(
                EVENT_KEYS.CONVERSATION.TEXT_MESSAGE_RECEIVED,
                {
                    sourceLanguage: result.originalLang,
                    targetLanguage: resultLocaleCode,
                    targetLength: targetText.length.toString()
                },
                {
                    from: result.participantId,
                    sourceText: result.text,
                    targetText,
                    sessionId,
                    roomCode: this.props.roomCode
                });

            const message = {
                participantId: result.participantId,
                participantName: this.participantNameForId(result.participantId),
                id: uuidv4(),
                sourceLanguage: result.originalLang,
                sourceText: ConversationUtils.areSameLanguage(result.originalLang, resultLocaleCode) ? undefined : result.text,
                targetLanguage: resultLocaleCode,
                targetText,
                timestamp: new Date().toUTCString(),
                type: MessageType.Final,
                message: ''
            };
            this.props.textMessageReceived(message)

            if (this.shouldRequestTts(message)) {
                const voice = this.getCurrentVoice();
                if (voice != null) {
                    this.props.getTts(message, resultLocaleCode, voice);
                }
            }
        }
    }

    transcribed(offset: number, result: spx.ConversationTranslationResult, sessionId: string): void {
        const resultLocaleCode = ConversationUtils.resultLocaleCodeFor(this.props.capitoLocaleCode);
        if (resultLocaleCode == null) {
            eventService.trackEvent(
                EVENT_KEYS.ERROR.NO_CAPITO_LOCALE_CODE,
                {
                    spxMethod: 'transcribed'
                },
                {
                    sessionId
                });
            return;
        }

        const targetText = this.targetTextFromResult(result, resultLocaleCode);
        if (targetText != null && targetText !== '$removed$') {
            this.lastFinalId = result.resultId;

            eventService.trackEvent(
                EVENT_KEYS.CONVERSATION.FINAL_RECEIVED,
                {
                    sourceLanguage: result.originalLang,
                    targetLanguage: resultLocaleCode,
                    targetLength: targetText.length.toString()
                },
                {
                    from: result.participantId,
                    sourceText: result.text,
                    targetText,
                    sessionId,
                    roomCode: this.props.roomCode
                });

            const message = {
                participantId: result.participantId,
                participantName: this.participantNameForId(result.participantId),
                id: uuidv4(),
                sourceLanguage: result.originalLang,
                sourceText: ConversationUtils.areSameLanguage(result.originalLang, resultLocaleCode) ? undefined : result.text,
                targetLanguage: resultLocaleCode,
                targetText,
                timestamp: new Date().toUTCString(),
                type: MessageType.Final,
                message: ''
            };
            this.props.finalReceived(message);

            if (this.shouldRequestTts(message)) {
                const voice = this.getCurrentVoice();
                if (voice != null) {
                    this.props.getTts(message, resultLocaleCode, voice);
                }
            }
        }
    }

    transcribing(offset: number, result: spx.ConversationTranslationResult, sessionId: string): void {
        if (!this.props.showPartials || this.isDanglingPartial(result.resultId)) { return }

        const resultLocaleCode = ConversationUtils.resultLocaleCodeFor(this.props.capitoLocaleCode);
        if (resultLocaleCode == null) {
            eventService.trackEvent(
                EVENT_KEYS.ERROR.NO_CAPITO_LOCALE_CODE,
                {
                    spxMethod: 'transcribing'
                },
                {
                    sessionId
                });
            return;
        }

        const targetText = this.targetTextFromResult(result, resultLocaleCode);
        if (targetText != null && targetText !== '$removed$') {
            eventService.trackEvent(
                EVENT_KEYS.CONVERSATION.PARTIAL_RECEIVED,
                {
                    sourceLanguage: result.originalLang,
                    targetLanguage: resultLocaleCode,
                    targetLength: targetText.length.toString()
                },
                {
                    from: result.participantId,
                    sourceText: result.text,
                    targetText,
                    sessionId,
                    roomCode: this.props.roomCode
                });

            this.props.partialReceived({
                participantId: result.participantId,
                participantName: this.participantNameForId(result.participantId),
                id: uuidv4(),
                sourceLanguage: result.originalLang,
                sourceText: ConversationUtils.areSameLanguage(result.originalLang, resultLocaleCode) ? undefined : result.text,
                targetLanguage: resultLocaleCode,
                targetText,
                timestamp: new Date().toUTCString(),
                type: MessageType.Partial,
                message: ''
            })
        }
    }

    startTranscribingSuccess(result: any): void {
        eventService.trackEvent(EVENT_KEYS.CONVERSATION.START_TRANSCRIBING_SUCCESS, undefined, { roomCode: this.props.roomCode });
    }

    startTranscribingFailure(error: any): void {
        eventService.trackEvent(EVENT_KEYS.CONVERSATION.START_TRANSCRIBING_FAILURE, { error }, { roomCode: this.props.roomCode });
        this.disableMic();
    }

    stopTranscribingSuccess(result: any): void {
        eventService.trackEvent(EVENT_KEYS.CONVERSATION.STOP_TRANSCRIBING_SUCCESS, undefined, { roomCode: this.props.roomCode });
    }

    stopTranscribingFailure(error: any): void {
        eventService.trackEvent(EVENT_KEYS.CONVERSATION.STOP_TRANSCRIBING_FAILURE, { error }, { roomCode: this.props.roomCode });
    }

    conversationLeaveSuccess(result: any): void {
        eventService.trackEvent(EVENT_KEYS.CONVERSATION.LEAVE_CONVERSATION_SUCCESS, undefined, { roomCode: this.props.roomCode });
    }

    conversationLeaveFailure(result: any): void {
        eventService.trackEvent(EVENT_KEYS.CONVERSATION.LEAVE_CONVERSATION_FAILURE, undefined, { roomCode: this.props.roomCode });
    }

    textMessageSendSuccess(result: any): void {
        eventService.trackEvent(EVENT_KEYS.CONVERSATION.TEXT_MESSAGE_SEND_SUCCESS, undefined, { roomCode: this.props.roomCode });
    }

    textMessageSendFailure(error: any): void {
        eventService.trackEvent(EVENT_KEYS.CONVERSATION.TEXT_MESSAGE_SEND_FAILURE, { error }, { roomCode: this.props.roomCode });
    }

    sessionStarted(sessionId: string): void {
        eventService.trackEvent(EVENT_KEYS.CONVERSATION.SESSION_STARTED, undefined, { roomCode: this.props.roomCode });
    }

    sessionStopped(sessionId: string): void {
        console.log('sessionStopped was invoked');

        if (!this.userDidLeave) {
            // AJN: not an error, should adjust nomenclature
            this.props.conversationError({ toastType: toast.TYPE.INFO, notificationType: Conversation.NotificationType.ConversationCancelled, details: 'disconnecting' });
            this.onSessionStopped();
            eventService.trackEvent(EVENT_KEYS.CONVERSATION.SESSION_STOPPED, undefined, { roomCode: this.props.roomCode });
        }
    }

    downloadTranscriptAndLeave(): void {
        this.downloadTranscript();
        this.leave();
    }

    downloadTranscript(): void {
        const charset: string = window.document.characterSet;
        const fileName = `${this.props.roomCode}.txt`;

        try {
            eventService.trackEvent(EVENT_KEYS.CONVERSATION.TRANSCRIPT_SAVED);

            const blob = new Blob([TranscriptCreator.getTranscript(this.props.roomCode, this.props.username, this.getAllMessages())], {
                type: `text/plain;charset=${charset}`
            });

            FileSaver.saveAs(blob, fileName);
        } catch (ex) {
            eventService.trackError(EVENT_KEYS.ERROR.TRANSCRIPT_DOWNLOAD_ERROR, ex)
        }
    }

    leave(): void {
        console.log('leave was invoked');
        // reset access validation on return to home
        this.props.setInvalidAccess();

        // AJN:
        //   if user left manually, don't need to do this again
        //   if user navigated to a different page and leaving hasn't yet been processed, we need to do this stuff
        if (this.userDidLeave) { return }
        this.userDidLeave = true;
        this.props.conversationService?.leaveConversation()
        clearInterval(this.state.durationIntervalId);
        this.disableMic();
        this.props.roomLeft();
        this.props.resetTtsState();
        this.props.history.goBack();
    }

    // AJN: conversation service will return translations keyed under the model used, which may be
    // different than the code we used to join. E.g., if you join w/ arabic UAE (ar-AE), translations
    // are returned under ar-EG, so we need to get the translation still instead of returning undefined
    targetTextFromResult(result: spx.ConversationTranslationResult, resultLocaleCode: string): string | undefined {
        let targetText = result.translations.get(resultLocaleCode);
        if (targetText === undefined) {
            const languageCode = ConversationUtils.languageCodeFromLocaleCode(resultLocaleCode);
            const targetLanguage = result.translations.languages.find((language) => {
                const translationLanguageCode = ConversationUtils.languageCodeFromLocaleCode(language);
                return translationLanguageCode === languageCode;
            });
            if (targetLanguage != null) {
                targetText = result.translations.get(targetLanguage);
            }
        }
        return targetText;
    }
}

// AJN: given an application state entity, map into the union'ed type defined as the props type for this component
export default connect(
    (state: ApplicationState.ApplicationState) => {
        return {
            validAccess: state.validation?.validAccess,
            languages: state.languages?.languages,
            isLoading: state.languages?.isLoading,
            startDateIndex: state.languages?.startDateIndex,
            selectedLanguageCode: state.languages?.selectedLanguageCode,
            selectedLocaleCode: state.languages?.selectedLocaleCode,
            selectedSpeechRegionCode: state.languages?.selectedSpeechRegionCode,
            capitoLocaleCode: state.languages?.capitoLocaleCode,
            voice: state.languages?.voice,
            roomCode: state.conversation?.roomCode,
            username: state.conversation?.username,
            messages: state.conversation?.messages,
            activePartial: state.conversation?.activePartial,
            participants: state.conversation?.participants,
            myParticipantId: state.conversation?.myParticipantId,
            autoTts: state.tts?.autoTts,
            showPartials: state.conversation?.showPartials,
            mostRecentMessageId: state.conversation?.mostRecentMessageId,
            conversationService: state.conversation?.conversationService
        }
    },
    ({
        ...Validation.actionCreators,
        ...Languages.actionCreators,
        ...Conversation.actionCreators,
        ...Tts.actionCreators
    })
)(ConversationComponent as any);
