import * as spx from 'microsoft-cognitiveservices-speech-sdk';
import { EVENT_KEYS } from '../types/constants';
import { IConversationDelegate, IJoinConversationDelegate } from '../types/conversationDelegate';
import { eventService } from './eventService';

export interface IConversationService {
    setConversationDelegate: (newDelegate: IConversationDelegate) => void;
    joinConversation: (
        code: string,
        localeCode: string,
        username: string,
        dataCollectionMode: string) => void;

    leaveConversation: () => void;

    startTranscribingAsync: (() => void) & (() => void);

    sendTextMessage: (message: string) => void;
}

export class ConversationService implements IConversationService {
    readonly _multiChannelAudioProperty: string = 'ConversationTranslator_MultiChannelAudio';
    readonly _multiChannelAudio: string = 'true';

    private readonly joinDelegate: IJoinConversationDelegate;
    conversationDelegate: IConversationDelegate | undefined;
    conversationTranslator: spx.ConversationTranslator | undefined;

    constructor(joinDelegate: IJoinConversationDelegate) {
        this.joinDelegate = joinDelegate;
    }

    setConversationDelegate(conversationDelegate: IConversationDelegate): void {
        this.conversationDelegate = conversationDelegate;
    }

    joinConversation(
        roomCode: string,
        localeCode: string,
        username: string,
        dataCollectionMode: string): void {
        const audioConfig: spx.AudioConfig = spx.AudioConfig.fromDefaultMicrophoneInput();

        // TODO:AJN: on the audio config, set Speech-LogFilename property to locally log
        this.conversationTranslator = new spx.ConversationTranslator(audioConfig);
        this.conversationTranslator.setServiceProperty('SpeechConfig-Context.DataCollection.Mode', dataCollectionMode);
        this.addListeners(this.conversationTranslator);

        try {
            this.conversationTranslator.joinConversationAsync(
                roomCode,
                username,
                localeCode,
                (result) => {
                    this.joinDelegate.conversationJoinSuccess(result);
                },
                (error) => {
                    this.joinDelegate.conversationJoinFailure(error);
                });
        } catch (e) {
            eventService.trackError(EVENT_KEYS.CONVERSATION.CONVERSATION_JOIN_EXCEPTION, e);
        }
    }

    leaveConversation(): void {
        this.conversationTranslator?.leaveConversationAsync(
            (result) => {
                this.conversationDelegate?.conversationLeaveSuccess(result);
            },

            (error) => {
                this.conversationDelegate?.conversationLeaveFailure(error);
            }
        );
    }

    startTranscribingAsync(): void {
        this.conversationTranslator?.startTranscribingAsync(
            (result) => {
                this.conversationDelegate?.startTranscribingSuccess(result);
            },
            (error) => {
                this.conversationDelegate?.startTranscribingFailure(error);
            }
        );
    }

    stopTranscribingAsync(): void {
        this.conversationTranslator?.stopTranscribingAsync(
            (result) => {
                this.conversationDelegate?.stopTranscribingSuccess(result);
            },
            (error) => {
                this.conversationDelegate?.stopTranscribingFailure(error);
            }
        );
    }

    sendTextMessage(message: string): void {
        if (this.conversationTranslator == null) {
            eventService.trackEvent(EVENT_KEYS.ERROR.CONVERSATION_TRANSLATOR_UNAVAILABLE);
            return;
        }

        this.conversationTranslator.sendTextMessageAsync(
            message,
            (result) => {
                this.conversationDelegate?.textMessageSendSuccess(result);
            },
            (error) => {
                this.conversationDelegate?.textMessageSendFailure(error);
            }
        )
    }

    addListeners(conversationTranslator: spx.ConversationTranslator): void {
        conversationTranslator.canceled = (s, e) => {
            this.conversationDelegate?.cancelled(e.errorCode, e.errorDetails, e.offset, e.reason, e.sessionId);
        };

        conversationTranslator.conversationExpiration = (s, e) => {
            this.conversationDelegate?.conversationExpiration(e.expirationTime, e.sessionId);
        };

        conversationTranslator.participantsChanged = (s, e) => {
            this.conversationDelegate?.participantsChanged(e.participants, e.reason, e.sessionId);
        }

        conversationTranslator.sessionStarted = (s, e) => {
            this.conversationDelegate?.sessionStarted(e.sessionId);
        };

        conversationTranslator.sessionStopped = (s, e) => {
            this.conversationDelegate?.sessionStopped(e.sessionId);
        };

        conversationTranslator.textMessageReceived = (s, e) => {
            this.conversationDelegate?.textMessageReceived(e.offset, e.result, e.sessionId);
        };

        conversationTranslator.transcribing = (s, e) => {
            this.conversationDelegate?.transcribing(e.offset, e.result, e.sessionId);
        };

        conversationTranslator.transcribed = (s, e) => {
            this.conversationDelegate?.transcribed(e.offset, e.result, e.sessionId);
        };
    }
}
