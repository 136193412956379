import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { ttsService } from '../common/services/ttsService';
import { TKTranslationVoice } from '../common/types/common';
import { Message } from './Conversation';

export interface TtsState {
    autoTts: boolean;
}

export interface TtsAudio {
    audioData: AudioBuffer;
    id: string;
}

export interface ToggleAutoTts {
    type: 'TOGGLE_AUTO_TTS';
}

export interface ResetState {
    type: 'RESET_STATE';
}

export type TtsAction = ToggleAutoTts | ResetState;

export const actionCreators = {
    getTts: (message: Message, languageCode: string, voice: TKTranslationVoice): AppThunkAction<TtsAction> => (dispatch, getState) => {
        void ttsService.getTts(message, voice.shortName, languageCode, dispatch, getState);
    },
    toggleAutoTts: (): AppThunkAction<TtsAction> => (dispatch, getState) => {
        if (ttsService.isPlayingTts) {
            ttsService.stopTtsPlayback();
        }
        dispatch({ type: 'TOGGLE_AUTO_TTS' });
    },
    resetTtsState: (): AppThunkAction<TtsAction> => (dispatch, getState) => {
        dispatch({ type: 'RESET_STATE' })
    }
}

const unloaded: TtsState = { autoTts: false };

export const reducer: Reducer<TtsState> = (state: TtsState | undefined, incomingAction: Action): TtsState => {
    if (state === undefined) {
        return unloaded;
    }

    const action = incomingAction as TtsAction;
    switch (action.type) {
        case 'TOGGLE_AUTO_TTS':
            return {
                autoTts: !state.autoTts
            };
        case 'RESET_STATE':
            return unloaded;
        default:
            return state;
    }
}
