import { AppsLanguage, LanguagesResponse, TKLocale } from '../types/common';
import { API_ROUTES } from '../types/constants';
import { KnownAction } from '../../store/Languages';
import { ApplicationState } from '../../store';
import baseService from './baseService';

export interface ILanguagesService {
    getLanguages: (startDateIndex: number, dispatch: (action: KnownAction) => void, getState: () => ApplicationState) => Promise<void>;
}

class LanguagesService implements ILanguagesService {
    public async getLanguages(startDateIndex: number, dispatch: (action: KnownAction) => void, getState: () => ApplicationState): Promise<void> {
        const response = await baseService.get<LanguagesResponse>(
            API_ROUTES.LANGUAGES,
            this.getHeaders(window.navigator.language, 'correlationIdTODO', 'etagTODO')
        );

        // TODO:AJN: implement cache? IMO not needed, this is cached at the .NET API level..

        // AJN: below is needed as .ts cannot trivially map from .net types to .ts
        const languagesMap = new Map<string, AppsLanguage>(Object.entries(response.data.languages).map(
            ([k, v]) => {
                const language = v as AppsLanguage;
                const localeMap = new Map<string, TKLocale>(Object.entries(language.locales).map(([k1, v1]) => [k1, v1]));
                language.locales = localeMap;
                return [k, language];
            }));

        dispatch({ type: 'RECEIVE_LANGUAGES', startDateIndex, languages: languagesMap });
    }

    private getHeaders(locale: string, correlationId: string, etag: string = ''): any {
        return {
            headers: {
                'Accept-Language': locale,
                'X-CorrelationId': correlationId,
                'If-None-Match': etag
            }
        };
    }
}

// singleton instance in the global namespace
export const languagesService = new LanguagesService();
