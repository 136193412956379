import React, { Component } from 'react';
import { localize } from '../common/types/common';
import { STATIC_CONTENT, EVENT_KEYS, UNLOCALIZED_STRINGS } from '../common/types/constants';
import UhfFooter from './UhfFooter';
import UhfHeader from './UhfHeader';
import * as Uhf from '../store/Uhf';
import { UhfState } from '../store/Uhf';
import '../common/design/contractSummary.css';
import { eventService } from '../common/services/eventService';
import { connect } from 'react-redux';
import * as ApplicationState from '../store';

interface ContractSummaryProps {
    uhfData?: UhfState;
    getUhf: (language: string) => void;
}

class ContractSummary extends Component<ContractSummaryProps> {
    public componentDidMount(): void {
        eventService.trackEvent(EVENT_KEYS.PAGE.CONTRACTSUMMARY_PAGE_LOADED);
        this.ensureDataFetched();
    }

    public render(): React.ReactElement {
        return (
            <React.Fragment>
                <div>
                    <UhfHeader />
                </div>
                <div className="content">
                    <div className="container">
                        <div className="microsoft-information">
                            <div>{UNLOCALIZED_STRINGS.CORPORATION_NAME}</div>
                            <div>{UNLOCALIZED_STRINGS.ADDRESS}</div>
                        </div>
                        <div id="contract-summary-date">
                            <div className="translator-bold">{UNLOCALIZED_STRINGS.PRODUCT_NAME}</div>
                            <div>{localize('CONTRACT_SUMMARY_DATE')}</div>
                        </div>
                        <div className="contract-body">
                            <div id="contract-title">{localize('CONTRACT_SUMMARY_HEADER')}</div>
                            <ul id="contract-bullets">
                                <li>
                                    {localize('CONTRACT_SUMMARY_FIRSTBULLET')} (<sup>1</sup>).
                                </li>
                                <li>{localize('CONTRACT_SUMMARY_SECONDBULLET')}</li>
                                <li>{localize('CONTRACT_SUMMER_THIRDBULLET')}</li>
                            </ul>
                            <div className="section-header">{localize('CONTRACT_SUMMARY_SERVICES')}</div>
                            <div className="paragraph">
                                {localize('CONTRACT_SUMMARY_PARAGRAPH_SENTENCE_ONE')} {localize('CONTRACT_SUMMARY_PARAGRAPH_SENTENCE_TWO')}
                                {localize('CONTRACT_SUMMARY_PARAGRAPH_SENTENCE_THREE')}  {localize('CONTRACT_SUMMARY_PARAGRAPH_SENTENCE_FOUR')}
                                <br></br>
                                <a href="https://translator.microsoft.com" rel="noopener noreferrer" target="_blank">{STATIC_CONTENT.TRANSLATOR}</a>.
                            </div>
                            <div className="section-header">{localize('CONTRACT_SUMMARY_PRICE')}</div>
                            <div className="paragraph">{localize('CONTRACT_SUMMARY_PRICE_SENTENCE')}</div>
                            <div className="section-header">{localize('CONTRACT_SUMMARY_DURATION')}</div>
                            <div className="paragraph">{localize('CONTRACT_SUMMARY_DURATION_SENTENCE')}</div>
                            <div className="section-header">{localize('CONTRACT_SUMMARY_FEATURES')}</div>
                            <div className="paragraph">
                                {localize('CONTRACT_SUMMARY_FEATURE_ONE')} + {localize('CONTRACT_SUMMARY_FEATURE_TWO')}
                                <br></br>
                                <a href="https://www.microsoft.com/accessibility" rel="noopener noreferrer" target="_blank">{STATIC_CONTENT.ACCESSIBILITY}</a>.
                            </div>
                            <div className="summary-reference">
                                <div className="reference">{localize('CONTRACT_SUMMARY_REFERENCE')}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <UhfFooter />
            </React.Fragment>
        );
    }

    private ensureDataFetched(): void {
        if (this.props.uhfData == null) {
            this.props.getUhf(window.navigator.language);
        }
    }
}
export default connect(
    (state: ApplicationState.ApplicationState) => {
        return {
            uhfData: state.uhf?.uhfData
        }
    },
    ({
        ...Uhf.actionCreators
    })
)(ContractSummary as any);
