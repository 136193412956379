import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const fallbackLanguage = 'en';
export const languages = ['en', 'ar', 'ca', 'da', 'de', 'es', 'fi', 'fr', 'he', 'hi', 'id', 'it', 'ja', 'ko', 'nl', 'no', 'pl', 'pt-BR', 'pt-PT', 'ru', 'sv', 'th', 'tr', 'zh-Hans', 'zh-Hant'];

const resources = languages.reduce((strings: Record<string, any>, lang) => {
    strings[lang] = {
        translation: require(`../locales/${lang}.json`)
    };
    return strings;
}, Object);

void i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        detection: { order: ['path', 'navigator'] },
        fallbackLng: fallbackLanguage,
        resources,
        debug: true
    });
