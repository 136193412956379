import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter, RouteComponentProps } from 'react-router-dom';
import ContractSummary from './components/ContractSummary';
import Conversation from './components/Conversation';
import Home from './components/Home';
import './custom.css';
import type * as ApplicationState from './store';
import type * as Validation from './store/Validation';
import * as PropTypes from 'prop-types';
import { eventService } from './common/services/eventService';
import { EVENT_KEYS } from './common/types/constants';

type ValidationProps = Validation.ValidationState & RouteComponentProps;

class App extends React.Component<ValidationProps> {
    static propTypes = {
        location: PropTypes.object.isRequired
    }

    componentDidUpdate(prevProps: any): void {
        if (this.props.location !== prevProps.location) {
            eventService.trackEvent(EVENT_KEYS.PAGE.APP_ROUTE_CHANGED, { from: prevProps.location.pathname, to: this.props.location.pathname })
        }
    }

    render(): React.ReactElement {
        return (
            <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/([a-zA-Z]{5})' component={Home} />
                <Route exact path='/join/([a-zA-Z]{5})' component={Home} />
                <Route exact path='/live/join/([a-zA-Z]{5})' component={Home} />

                {this.props.validAccess && (<Route path='/Conversation' component={Conversation} />)}
                {(<Route path='/ContractSummary' component={ContractSummary} />)}
                <Redirect from='*' to='/' />
            </Switch>
        )
    }
}

const connectedApp = connect(
    (state: ApplicationState.ApplicationState) => {
        return {
            validAccess: state.validation?.validAccess
        }
    },
    null
)(App);

export default withRouter(connectedApp);
