import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

export interface ValidationState {
    validAccess: boolean;
}

export interface SetValidAccess {
    type: 'SET_VALID_ACCESS';
}

export interface SetInvalidAccess {
    type: 'SET_INVALID_ACCESS';
}

export type KnownAction = SetValidAccess | SetInvalidAccess;

export const actionCreators = {
    setValidAccess: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_VALID_ACCESS' });
    },
    setInvalidAccess: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_INVALID_ACCESS' });
    }
};

const unloadedState: ValidationState = {
    validAccess: false
};

export const reducer: Reducer<ValidationState> = (state: ValidationState | undefined, incomingAction: Action): ValidationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SET_VALID_ACCESS':
            return { validAccess: true };
        case 'SET_INVALID_ACCESS':
            return { validAccess: false };
        default:
            return state;
    }
};
