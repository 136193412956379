import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { versionService } from '../common/services/versionService';

export interface VersionState {
    version: string | undefined;
}

export interface SetAppVersion {
    type: 'SET_APP_VERSION';
    version: string | undefined;
}

export type VersionAction = SetAppVersion;

export const actionCreators = {
    getVersion: (): AppThunkAction<VersionAction> => (dispatch, getState) => {
        void versionService.getVersion(dispatch, getState);
    }
}

const unloaded: VersionState = { version: undefined };

export const reducer: Reducer<VersionState> = (state: VersionState | undefined, incomingAction: Action): VersionState => {
    if (state === undefined) {
        return unloaded;
    }

    const action = incomingAction as VersionAction;
    switch (action.type) {
        case 'SET_APP_VERSION':
            return {
                version: action.version
            };
        default:
            return state;
    }
}
