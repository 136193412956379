import baseService from './baseService';
import { ApplicationState } from '../../store';
import { UhfAction } from '../../store/Uhf';
import { ShellModel } from '../models/shellModel';
import { API_ROUTES } from '../types/constants';

export interface IUhfService {
    getUhf: (locale: string, dispatch: (action: UhfAction) => void, getState: () => ApplicationState) => Promise<void>;
}

class UhfService implements IUhfService {
    public async getUhf(
        locale: string,
        dispatch: (action: UhfAction) => void,
        getState: () => ApplicationState
    ): Promise<void> {
        const response = await baseService.get<ShellModel>(`${API_ROUTES.UHF}${locale}`);
        dispatch({ type: 'SET_UHF_DATA', uhfData: response.data });
    }
}

export const uhfService = new UhfService();
