import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast, ToastContainer, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as rs from 'reactstrap';
import '../common/config/i18n';
import '../common/design/home.css';
import { eventService } from '../common/services/eventService';
import { logService } from '../common/services/logService';
import { ConversationService } from '../common/services/speechService';
import { AppsLanguage, sortedLanguageEntries, TKLocale, TKTranslationRegion, localize, getForcedColorsState } from '../common/types/common';
import { CONVERSATION_ERROR_CODES, ENDPOINT, EVENT_KEYS, STATIC_CONTENT, STORAGE, UNLOCALIZED_STRINGS, VALUES } from '../common/types/constants';
import { IJoinConversationDelegate } from '../common/types/conversationDelegate';
import * as ApplicationState from '../store';
import * as Conversation from '../store/Conversation';
import * as Languages from '../store/Languages';
import * as Validation from '../store/Validation';
import * as Version from '../store/Version';
import * as Uhf from '../store/Uhf';
import UhfFooter from './UhfFooter';
import UhfHeader from './UhfHeader';

// icons
import amazon_icon from '../common/icons/amazon.svg';
import amazon_icon_fc from '../common/icons/amazon_forced_colors.svg';
import android_icon from '../common/icons/android.svg';
import android_icon_fc from '../common/icons/android_forced_colors.svg';
import apple_icon from '../common/icons/ios.svg';
import apple_icon_fc from '../common/icons/ios_forced_colors.svg';
import mic_icon from '../common/icons/mic_on.svg';
import mic_on_icon_fc from '../common/icons/mic_on_forced_colors.svg';
import group_transcribe_icon from '../common/images/GroupTranscribe.png';
// AJN: share icon has several fill colors so was not a visibility issue on HC
import share_icon from '../common/images/share.svg';
import logo from '../common/images/translator-logo.png';

type LanguageProps =
    Languages.LanguagesState
    & Conversation.ConversationState
    & Version.VersionState
    & Uhf.UhfState
    & typeof Validation.actionCreators
    & typeof Languages.actionCreators
    & typeof Conversation.actionCreators
    & typeof Version.actionCreators
    & typeof Uhf.actionCreators
    & RouteComponentProps<{ startDateIndex: string }>;

class Home extends React.PureComponent<LanguageProps> implements IJoinConversationDelegate {
    // data collection turned off for now
    _dataCollectionMode: string = '0';

    private readonly toastOptions: ToastOptions = { autoClose: false }

    public componentDidMount(): void {
        eventService.trackEvent(EVENT_KEYS.PAGE.HOME_PAGE_LOADED);
        this.updateRoomcodeFromPath()
        this.ensureDataFetched();
        this.handleNotification();
        this.ensureDefaults();
    }

    public componentDidUpdate(): void {
        this.handleNotification();
        this.ensureDataFetched();
        this.setState({ headerReady: true });
        this.ensureDefaults();
    }

    // TO DO: For Form<> add a onSubmit to direct it to what it should do when user hits "join conversation"
    // TODO: NC - Modify CSS to fit the UHF and then remove navitem!
    public render(): React.ReactElement {
        const forcedColorsIsSupportedAndEnabled: boolean = getForcedColorsState();

        return (
            <React.Fragment>
                <div className={this.state.headerReady ? 'slideDown' : ''} >
                    <UhfHeader />
                </div>
                <div className="content-wrapper">
                    {this.state.showLoadingSpinner && <div className="spinner"></div>}
                    <rs.Container className="content-container">
                        <rs.Row className="justify-content-center">
                            <rs.CardGroup>
                                <rs.Col xs={11} md={8} lg={6}>
                                    <rs.Card className="mainForm">
                                        <rs.CardHeader aria-label={localize('JOIN_CONVERSATION')} className="title w-100">{localize('JOIN_CONVERSATION')}</rs.CardHeader>
                                        <rs.CardBody className="card-body-padding">
                                            <rs.Form onSubmit={this.joinConversation}>
                                                <rs.FormGroup>
                                                    {this.addRoomcodeInput()}
                                                    {this.addNameInput()}
                                                    {this.addLanguageList()}
                                                    {this.addRegionList()}
                                                </rs.FormGroup>
                                                <rs.Button className="submit" type="submit">{localize('JOIN_CONVERSATION')}</rs.Button>
                                            </rs.Form>
                                        </rs.CardBody>
                                        <rs.CardFooter className="text-muted w-100 footer-bottom">
                                            <rs.Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                                <rs.DropdownToggle
                                                    className="dropdown-toggle learnMore"
                                                    aria-label={localize('LEARN_MORE')}>
                                                    {localize('LEARN_MORE')}
                                                </rs.DropdownToggle>
                                                <rs.DropdownMenu>
                                                    <rs.DropdownItem
                                                        className="learnMoreItem"
                                                        onClick={() => { eventService.trackEvent(EVENT_KEYS.PAGE.PRIVACY_PAGE_LOADED); window.open(STATIC_CONTENT.PRIVACY_PAGE, ''); }}>
                                                        <rs.CardLink
                                                            className="link"
                                                            target="_blank"
                                                            aria-label={localize('PRIVACY_LINK')}>
                                                            {localize('PRIVACY_LINK')}
                                                        </rs.CardLink>
                                                    </rs.DropdownItem>
                                                    <rs.DropdownItem
                                                        className="learnMoreItem"
                                                        onClick={() => { eventService.trackEvent(EVENT_KEYS.PAGE.SERVICE_PAGE_LOADED); window.open(STATIC_CONTENT.SERVICES_PAGE, ''); }}>
                                                        <rs.CardLink
                                                            className="link"
                                                            target="_blank"
                                                            aria-label={localize('SERVICE_LINK')}>
                                                            {localize('SERVICE_LINK')}
                                                        </rs.CardLink>
                                                    </rs.DropdownItem>
                                                    <rs.DropdownItem
                                                        className="learnMoreItem"
                                                        onClick={() => { eventService.trackEvent(EVENT_KEYS.PAGE.CONTRACTSUMMARY_PAGE_CLICKED); window.open(STATIC_CONTENT.CONTRACT_SUMMARY_PAGE, ''); }}>
                                                        <rs.CardLink
                                                            className="link"
                                                            target="_blank"
                                                            aria-label={localize('CONTRACT_LINK')}>
                                                            {localize('CONTRACT_LINK')}
                                                        </rs.CardLink>
                                                    </rs.DropdownItem>
                                                    <rs.DropdownItem
                                                        className="learnMoreItem"
                                                        onClick={() => { eventService.trackEvent(EVENT_KEYS.PAGE.FAQ_PAGE_LOADED); window.open(STATIC_CONTENT.WEB_FAQ_PAGE, ''); }}>
                                                        <rs.CardLink
                                                            className="link"
                                                            target="_blank"
                                                            aria-label={localize('FAQ')}>
                                                            {localize('FAQ')}
                                                        </rs.CardLink>
                                                    </rs.DropdownItem>
                                                    <rs.DropdownItem
                                                        className="learnMoreItem"
                                                        onClick={() => { eventService.trackEvent(EVENT_KEYS.PAGE.HELP_PAGE_LOADED); window.open(STATIC_CONTENT.HELP_PAGE, ''); }}>
                                                        <rs.CardLink
                                                            className="link"
                                                            target="_blank"
                                                            aria-label={localize('REPORT_ISSUES')}>
                                                            {localize('REPORT_ISSUES')}
                                                        </rs.CardLink>
                                                    </rs.DropdownItem>
                                                    <rs.DropdownItem
                                                        className="learnMoreItem"
                                                        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                                                        onClick={() => { eventService.trackEvent(EVENT_KEYS.PAGE.TPN_PAGE_LOADED); window.open(process.env.PUBLIC_URL + STATIC_CONTENT.TPN_PAGE, ''); }}>
                                                        <rs.CardLink
                                                            className="link"
                                                            target="_blank"
                                                            aria-label={localize('TPN')}>
                                                            {localize('TPN')}
                                                        </rs.CardLink>
                                                    </rs.DropdownItem>
                                                    <rs.DropdownItem
                                                        className="learnMoreItem"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            logService.saveLogFile();
                                                        }}>
                                                        <rs.CardLink
                                                            className="link"
                                                            aria-label={localize('DOWNLOAD_LOGS_TITLE')}
                                                            target="_blank">
                                                            {localize('DOWNLOAD_LOGS_TITLE')}
                                                        </rs.CardLink>
                                                    </rs.DropdownItem>
                                                    <rs.DropdownItem disabled className="d-flex justify-content-start">
                                                        <rs.Label className="version" aria-label={this.props.version}>{this.props.version}</rs.Label>
                                                    </rs.DropdownItem>
                                                </rs.DropdownMenu>
                                            </rs.Dropdown>
                                        </rs.CardFooter>
                                    </rs.Card>
                                </rs.Col>
                                <rs.Col xs={11} md={8} lg={6}>
                                    <rs.Card className="infoForm">
                                        <rs.CardBody className="card-body-padding">
                                            <div className="cardContentWrapper">
                                                <img src={logo} alt={UNLOCALIZED_STRINGS.PRODUCT_NAME} className="logo" />
                                                <div className="cardContentContainer">
                                                    <rs.CardTitle className="infoTitle">{localize('BREAK_LANGUAGE_BARRIERS')}</rs.CardTitle>
                                                    <rs.CardSubtitle className="infoSubheading">{localize('INFO_SUB_HEADING')}</rs.CardSubtitle>
                                                    <br></br>
                                                </div></div>
                                            <rs.CardTitle className="howToHeader">{localize('HOW_TO_HEADER')}</rs.CardTitle>
                                            <br></br>
                                            <rs.Row className="howToImages responsiveRow">
                                                <rs.Col className="col-md-4 col-sm-12">
                                                    <img className="howtoImages" src={group_transcribe_icon} alt={localize('START')} />
                                                    <rs.CardTitle className="howToTitle">{localize('HOW_TO_TITLE')}</rs.CardTitle>
                                                    <rs.Label>{localize('HOW_TO_ONE')}</rs.Label>
                                                    {this.socialIcons(forcedColorsIsSupportedAndEnabled)}
                                                </rs.Col>
                                                <rs.Col className="col-md-4 col-sm-12">
                                                    <img className="howtoImages" src={share_icon} alt={localize('SHARE')} />
                                                    <rs.CardTitle className="howToTitle">{localize('HOW_TO_SHARE')}</rs.CardTitle>
                                                    <rs.Label>{localize('HOW_TO_TWO')}</rs.Label>
                                                </rs.Col>
                                                <rs.Col className="col-md-4 col-sm-12">
                                                    <img className="howtoImages" src={forcedColorsIsSupportedAndEnabled ? mic_on_icon_fc : mic_icon} alt={localize('SPEAK')} />
                                                    <rs.CardTitle className="howToTitle">{localize('HOW_TO_SPEAK')}</rs.CardTitle>
                                                    <rs.Label>{localize('HOW_TO_THREE')}</rs.Label>
                                                </rs.Col>
                                            </rs.Row>
                                        </rs.CardBody>
                                    </rs.Card>
                                </rs.Col>
                            </rs.CardGroup>
                        </rs.Row>
                    </rs.Container>
                    <ToastContainer />
                    <UhfFooter />
                </div>
            </React.Fragment>
        );
    }

    public state = {
        showLoadingSpinner: false,
        dropdownOpen: false,
        headerReady: false
    };

    private readonly toggleDropdown = (): void => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen })
    };

    private toggleShowLoadingSpinner(): void {
        this.setState({ showLoadingSpinner: !this.state.showLoadingSpinner })
    }

    private socialIcons(supportsForcedColorsAndActive: boolean): React.ReactElement {
        return (
            <div className="share-icons">
                <a href="https://aka.ms/translatorweb-apple-store" target="_blank" rel="noopener noreferrer">
                    <img src={supportsForcedColorsAndActive ? apple_icon_fc : apple_icon} alt={localize('DOWNLOAD_APP_LINKS') + UNLOCALIZED_STRINGS.APPLE_NAME} />
                </a>
                <a href="https://aka.ms/translatorweb-android-store" target="_blank" rel="noopener noreferrer">
                    <img src={supportsForcedColorsAndActive ? android_icon_fc : android_icon} alt={localize('DOWNLOAD_APP_LINKS') + UNLOCALIZED_STRINGS.ANDROID_NAME} />
                </a>
                <a href=" https://aka.ms/translatorweb-amazon-store" target="_blank" rel="noopener noreferrer">
                    <img src={supportsForcedColorsAndActive ? amazon_icon_fc : amazon_icon} alt={localize('DOWNLOAD_APP_LINKS') + UNLOCALIZED_STRINGS.AMAZON_NAME} />
                </a>
            </div>
        );
    }

    private readonly joinConversation = (e: any): void => {
        e.preventDefault();
        const capitoLocaleCode = this.props.capitoLocaleCode;
        if (capitoLocaleCode == null) {
            eventService.trackEvent(EVENT_KEYS.ERROR.NO_CAPITO_LOCALE_CODE);
            this.props.conversationError({ toastType: toast.TYPE.ERROR, notificationType: Conversation.NotificationType.InvalidLocaleCode, details: '' });
            return;
        }

        this.toggleShowLoadingSpinner();
        if (this.validate()) {
            this.props.setValidAccess();
            const conversationService = new ConversationService(this);
            this.props.setConversationService(conversationService);
            conversationService.joinConversation(
                this.props.roomCode,
                capitoLocaleCode,
                this.props.username,
                this._dataCollectionMode);
        }
    }

    private validate(): boolean {
        return this.props.roomCode.length === VALUES.ROOMCODE_LENGTH &&
            this.props.username.length >= VALUES.USERNAME_MIN_LENGTH &&
            this.props.username.length <= VALUES.USERNAME_MAX_LENGTH;
    }

    // AJN: if redux props don't match local storage previously set values, update redux props
    private ensureDefaults(): void {
        const languageCode = this.selectedLanguageCode();
        const localeCode = this.selectedLocaleCode();
        const speechRegionCode = this.selectedSpeechRegionCode();
        if (languageCode !== this.props.selectedLanguageCode || localeCode !== this.props.selectedLocaleCode || speechRegionCode !== this.props.selectedSpeechRegionCode) {
            this.props.setLanguageAndSpeechRegion(languageCode, localeCode, speechRegionCode);
        }

        const username = this.selectedUsername();
        if (username !== this.props.username) {
            this.props.usernameUpdated(username);
        }
    }

    private ensureDataFetched(): void {
        if (this.props.version == null) {
            this.props.getVersion();
        }
        let startDateIndex = parseInt(this.props.match.params.startDateIndex, 10);
        if (isNaN(startDateIndex)) {
            startDateIndex = 0;
        }

        if (this.props.languages == null) {
            this.props.requestLanguages(startDateIndex);
        }

        if (this.props.uhfData == null) {
            this.props.getUhf(window.navigator.language);
        }
    }

    private selectedLanguageCode(): string { return localStorage.getItem(STORAGE.LANGUAGE_CODE) ?? this.props.selectedLanguageCode }
    private selectedLocaleCode(): string { return localStorage.getItem(STORAGE.LOCALE_CODE) ?? this.props.selectedLocaleCode }
    private selectedSpeechRegionCode(): string | undefined { return localStorage.getItem(STORAGE.SPEECH_REGION_CODE) ?? this.props.selectedSpeechRegionCode }
    private selectedUsername(): string { return localStorage.getItem(STORAGE.USERNAME) ?? this.props.username }

    private languageLocaleKey(languageCode: string, localeCode: string): string {
        return `${languageCode}_${localeCode}`;
    }

    private languageCodeFromKey(key: string): string {
        return key.split('_')[0];
    }

    private localeCodeFromKey(key: string): string {
        return key.split('_')[1];
    }

    private addLanguageList(): React.ReactElement {
        const languages = this.props.languages ?? new Map<string, AppsLanguage>();
        // if no langs available yet, show blank input.
        // NB: MUST BE UNNAMED, ELSE REACT WON'T REEVALUATE THE DEFAULT VALUE POST LANGUAGE LOAD AND THE LANGUAGE WILL BE INCONSISTENT
        if (languages.size === 0) {
            return (
                <div>
                    <rs.Label
                        className="cardrs.Label"
                        for="language"
                        aria-label={localize('YOUR_LANGUAGE')}>
                        {localize('YOUR_LANGUAGE')}
                    </rs.Label>
                    <rs.Input className="languageListInput" type="select" disabled={true}/>
                </div>
            );
        }

        const currentLanguageLocaleKey = this.currentLanguageLocaleKey();
        return (
            <div>
                <rs.Label
                    className="cardrs.Label"
                    for="language"
                    aria-label={localize('YOUR_LANGUAGE')}>
                    {localize('YOUR_LANGUAGE')}
                </rs.Label>
                <rs.Input
                    className="languageListInput"
                    type="select"
                    aria-label={localize('ARIA_CURRENT_LANGUAGE')}
                    name={localize('LANGUAGE')}
                    key={currentLanguageLocaleKey}
                    defaultValue={currentLanguageLocaleKey}
                    required
                    onChange={this.onLanguageSelected}>
                    <optgroup
                        aria-label={localize('SPEECH_AND_TEXT_LANGUAGES')}
                        label={localize('SPEECH_AND_TEXT_LANGUAGES')}>
                        {
                            [...sortedLanguageEntries(languages)].map((languageEntry: [string, AppsLanguage]) =>
                                [...languageEntry[1].locales.entries()]
                                    .filter((localeEntry: [string, TKLocale]) => localeEntry[1].features.speech && localeEntry[1].features.text)
                                    .map((localeEntry: [string, TKLocale]) =>
                                        <option
                                            key={localeEntry[0]}
                                            value={this.languageLocaleKey(languageEntry[0], localeEntry[0])}
                                            aria-label={localeEntry[1].metadata.name}>
                                            {localeEntry[1].metadata.name}
                                        </option>
                                    )
                            )
                        }
                    </optgroup>
                    <optgroup
                        aria-label={localize('TEXT_ONLY_LANGUAGES')}
                        label={localize('TEXT_ONLY_LANGUAGES')}>
                        {
                            [...sortedLanguageEntries(languages)].map((languageEntry: [string, AppsLanguage]) =>
                                [...languageEntry[1].locales.entries()]
                                    .filter((localeEntry: [string, TKLocale]) => !localeEntry[1].features.speech && localeEntry[1].features.text)
                                    .map((localeEntry: [string, TKLocale]) =>
                                        <option
                                            key={localeEntry[0]}
                                            value={this.languageLocaleKey(languageEntry[0], localeEntry[0])}
                                            aria-label={localeEntry[1].metadata.name}>
                                            {localeEntry[1].metadata.name}
                                        </option>
                                    )
                            )
                        }
                    </optgroup>
                </rs.Input>
            </div>
        );
    }

    private addRegionList(): React.ReactElement {
        const speechLocales = this.currentLanguage()?.speechLocales ?? [];
        const localeSupportsSpeech = this.currentLocale()?.features.speech ?? false;
        if (speechLocales.length < 1 || !localeSupportsSpeech) {
            return (
                <div>
                    <rs.Label
                        className="cardrs.Label"
                        aria-label={localize('YOUR_SPEECH_REGION')}
                        for="speechRegion">
                        {localize('YOUR_SPEECH_REGION')}
                    </rs.Label>
                    <rs.Input className="selectRegionInput" type="select" disabled={true} />
                    <div
                        className="speechRegionFooter"
                        aria-label={localize('YOUR_SPEECH_REGION_FOOTER')}>
                        {localize('YOUR_SPEECH_REGION_FOOTER')}
                    </div>
                </div>
            );
        }

        return (
            <div>
                <rs.Label
                    className="cardrs.Label"
                    aria-label={localize('YOUR_SPEECH_REGION')}
                    for="speechRegion">
                    {localize('YOUR_SPEECH_REGION')}
                </rs.Label>
                <rs.Input
                    className="selectRegionInput"
                    type="select"
                    aria-label={localize('ARIA_CURRENT_SPEECH_REGION')}
                    name={localize('SPEECH_REGION')}
                    key={this.selectedSpeechRegionCode()}
                    defaultValue={this.currentSpeechRegionCode()}
                    required
                    onChange={this.onSpeechRegionSelected}>
                    {
                        speechLocales.map((speechRegion: TKTranslationRegion) =>
                            <option key={speechRegion.code} value={speechRegion.code} aria-label={speechRegion.name}>
                                {speechRegion.name}
                            </option>)
                    }
                </rs.Input>
                <div
                    className="speechRegionFooter"
                    aria-label={localize('YOUR_SPEECH_REGION_FOOTER')}>
                    {localize('YOUR_SPEECH_REGION_FOOTER')}
                </div>
            </div>
        );
    }

    private addNameInput(): React.ReactElement {
        return (
            <div>
                <rs.Label className="cardrs.Label" aria-label={localize('YOUR_NAME')} for="name">{localize('YOUR_NAME')}</rs.Label>
                <rs.Input
                    className="nameInput"
                    type="text"
                    defaultValue={this.selectedUsername()}
                    required
                    minLength={VALUES.USERNAME_MIN_LENGTH}
                    maxLength={VALUES.USERNAME_MAX_LENGTH}
                    name={localize('YOUR_NAME')}
                    aria-label={localize('USERNAME')}
                    placeholder={localize('ENTER_USER_NAME')}
                    onChange={this.onUsernameEntry}>
                </rs.Input>
            </div>
        );
    }

    private addRoomcodeInput(): React.ReactElement {
        return (
            <div>
                <rs.Label for="conversationCode" aria-label={localize('CONVERSATION_CODE')}>{localize('CONVERSATION_CODE')}</rs.Label>
                <rs.Input
                    className={this.props.roomCode != null && this.props.roomCode.length > 0 ? 'roomcodeInput' : 'roomcodeInputEmpty'}
                    type="text"
                    required
                    minLength={VALUES.ROOMCODE_LENGTH}
                    maxLength={VALUES.ROOMCODE_LENGTH}
                    name={localize('CONVERSATION_CODE')}
                    aria-label={localize('ENTER_ROOM_CODE')}
                    defaultValue={this.getRoomCodeText()}
                    placeholder={localize('ENTER_ROOM_CODE')}
                    onChange={this.onRoomcodeEntry}
                />
            </div>
        );
    }

    private updateRoomcodeFromPath(): void {
        const pathname = this.props.location.pathname;
        eventService.trackEvent(EVENT_KEYS.PAGE.HOME_PAGE_PATHNAME, { pathname });
        if (pathname.includes(ENDPOINT.JOIN_NAME)) {
            const pieces = pathname.split('/');
            if (pieces.length > 1) {
                const lastPiece = pieces[pieces.length - 1];
                if (lastPiece.length === 5) {
                    const roomCode = lastPiece;
                    this.props.roomCodeUpdated(roomCode);
                }
            }
        }
    }

    private handleNotification(): void {
        if (this.props.notification != null) {
            const details = this.props.notification.details;
            let message: string = '';
            switch (this.props.notification.notificationType) {
                case Conversation.NotificationType.ConversationCancelled:
                    if (details.includes(CONVERSATION_ERROR_CODES.DISCONNECTED_CONVERSATION)) {
                        message = localize('CONVERSATION_DISCONNECTED');
                    } else {
                        message = localize('CONVERSATION_CANCELLED');
                    }
                    break;
                case Conversation.NotificationType.JoinError:
                    if (details.includes(CONVERSATION_ERROR_CODES.ROOM_CODE_NOT_FOUND)) {
                        message = localize('ROOM_CODE_NOT_FOUND');
                    } else if (details.includes(CONVERSATION_ERROR_CODES.NICKNAME_ALREADY_TAKEN)) {
                        message = localize('INVALID_NICKNAME');
                    } else if (details.includes(CONVERSATION_ERROR_CODES.SECURITY_ERROR)) {
                        message = localize('BROWSER_NOT_SUPPORTED');
                    } else {
                        message = localize('JOIN_ERROR');
                    }
                    break;
                case Conversation.NotificationType.NoLocaleSelected:
                    message = localize('NO_LANGUAGE_SELECTED');
                    break;
                case Conversation.NotificationType.InvalidRoomCodeFormat:
                    message = localize('ROOM_CODE_FORMAT');
                    break;
                case Conversation.NotificationType.InvalidUserName:
                    message = localize('INVALID_NICKNAME');
                    break;
                case Conversation.NotificationType.InvalidConversationMetadata:
                    message = localize('INVALID_CONVERSATION_METADATA');
                    break;
                case Conversation.NotificationType.InvalidLocaleCode:
                    message = localize('INVALID_LOCALE');
                    break;
                default:
                    message = localize('UNIDENTIFIED_ERROR');
                    break;
            }

            switch (this.props.notification.toastType) {
                case toast.TYPE.INFO:
                    toast.info(message, this.toastOptions);
                    break;
                case toast.TYPE.ERROR:
                    toast.error(message, this.toastOptions);
                    break;
                case toast.TYPE.WARNING:
                    toast.warning(message, this.toastOptions);
                    break;
                case toast.TYPE.SUCCESS:
                    toast.success(message, this.toastOptions);
                    break;
                case toast.TYPE.DEFAULT:
                    toast(message, this.toastOptions);
            }

            this.props.notificationHandled();
        }
    }

    private getRoomCodeText(): string | undefined {
        if (this.props.roomCode != null && this.props.roomCode.length === 5) {
            return this.props.roomCode;
        }

        return undefined;
    }

    private currentLanguage(): AppsLanguage | undefined {
        if ((this.props.languages == null) || this.props.languages.size === 0) { return undefined; }
        return this.props.languages.get(this.selectedLanguageCode());
    }

    private currentLocale(): TKLocale | undefined {
        if ((this.props.languages == null) || this.props.languages.size === 0) { return undefined; }
        const language = this.props.languages.get(this.selectedLanguageCode());
        if (language == null) { return undefined; }
        return language.locales.get(this.selectedLocaleCode());
    }

    private currentLanguageLocaleKey(): string | undefined {
        const languageCode = this.selectedLanguageCode();
        const localeCode = this.selectedLocaleCode();
        return this.languageLocaleKey(languageCode, localeCode);
    }

    private currentSpeechRegionCode(): string | undefined {
        const regionCode = this.selectedSpeechRegionCode();
        if (regionCode != null) { return regionCode }
        const language = this.currentLanguage();
        if (language == null) { return undefined }
        const locale = language.locales.get(this.selectedLocaleCode());
        if ((locale == null) || !locale.features.speech) { return undefined; }

        return this.defaultSpeechRegion(language, locale)?.code;
    }

    readonly onRoomcodeEntry: React.ChangeEventHandler<HTMLInputElement> = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.roomCodeUpdated(event.target.value);
    }

    readonly onUsernameEntry: React.ChangeEventHandler<HTMLInputElement> = (event: React.ChangeEvent<HTMLInputElement>) => {
        const trimmedUsername = event.target.value.trim();
        localStorage.setItem('username', trimmedUsername);
        this.props.usernameUpdated(trimmedUsername);
    }

    readonly onLanguageSelected: React.ChangeEventHandler<HTMLInputElement> = (event: React.ChangeEvent<HTMLInputElement>) => {
        const languageLocaleKey = event.target.value;
        const languageCode = this.languageCodeFromKey(languageLocaleKey);
        const localeCode = this.localeCodeFromKey(languageLocaleKey)
        const speechRegionCode = this.defaultRegionCodeFromCodes(languageCode, localeCode);
        this.props.setLanguageAndSpeechRegion(languageCode, localeCode, speechRegionCode);

        localStorage.setItem('languageCode', languageCode);
        localStorage.setItem('localeCode', localeCode);
        if (speechRegionCode != null) {
            localStorage.setItem('speechRegionCode', speechRegionCode);
        } else {
            localStorage.removeItem('speechRegionCode');
        }
    }

    readonly onSpeechRegionSelected: React.ChangeEventHandler<HTMLInputElement> = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.setSpeechRegion(event.target.value);
        localStorage.setItem('speechRegionCode', event.target.value);
    }

    private defaultSpeechRegion(language: AppsLanguage, locale: TKLocale): TKTranslationRegion | undefined {
        const defaultCode = locale.metadata.defaultSpeechCode;
        if (defaultCode == null) { return undefined; }
        const pieces = defaultCode.split('-');
        const defaultRegionCode = pieces[pieces.length - 1];

        return this.regionMatchingCode(language, defaultRegionCode);
    }

    private regionMatchingCode(language: AppsLanguage, regionCode: string): TKTranslationRegion | undefined {
        const speechRegions = language.speechLocales;
        if (speechRegions != null && speechRegions.length > 0) {
            const matchingRegion = speechRegions.filter((region) => region.code.toLowerCase() === regionCode.toLowerCase());
            if (matchingRegion != null && matchingRegion.length > 0) {
                return matchingRegion[0];
            }
        }
    }

    private defaultRegionCodeFromCodes(languageCode: string, localeCode: string): string | undefined {
        const language = this.props.languages?.get(languageCode);
        const locale = language?.locales.get(localeCode);
        if ((language == null) || (locale == null)) { return undefined; }
        return this.defaultRegionCodeFor(language, locale);
    }

    private defaultRegionCodeFor(language: AppsLanguage, locale: TKLocale): string | undefined {
        return this.defaultSpeechRegion(language, locale)?.code
    }

    conversationJoinSuccess(result: any): void {
        this.toggleShowLoadingSpinner();
        this.props.history.push('/Conversation');
    };

    conversationJoinFailure(error: string): void {
        this.toggleShowLoadingSpinner();
        eventService.trackError(EVENT_KEYS.CONVERSATION.MEETING_JOIN_FAILURE, error);
        this.props.conversationError({ toastType: toast.TYPE.ERROR, notificationType: Conversation.NotificationType.JoinError, details: error });
        this.props.setConversationService(undefined);
    };
}

export default connect(
    (state: ApplicationState.ApplicationState) => {
        return {
            languages: state.languages?.languages,
            isLoading: state.languages?.isLoading,
            startDateIndex: state.languages?.startDateIndex,
            selectedLanguageCode: state.languages?.selectedLanguageCode,
            selectedLocaleCode: state.languages?.selectedLocaleCode,
            selectedSpeechRegionCode: state.languages?.selectedSpeechRegionCode,
            capitoLocaleCode: state.languages?.capitoLocaleCode,
            roomCode: state.conversation?.roomCode,
            username: state.conversation?.username,
            messages: state.conversation?.messages,
            activePartial: state.conversation?.activePartial,
            notification: state.conversation?.notification,
            version: state.version?.version,
            uhfData: state.uhf?.uhfData
        }
    },
    ({
        ...Validation.actionCreators,
        ...Languages.actionCreators,
        ...Conversation.actionCreators,
        ...Version.actionCreators,
        ...Uhf.actionCreators
    })
)(Home as any);
