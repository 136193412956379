import FileSaver from 'file-saver';

export interface ILogService {
    reset: () => void;
    trackEvent: (eventType: string, props: any | undefined, logOnlyProps: any | undefined) => void;
    saveLogFile: () => void;
}

class LogService implements ILogService {
    private logStrings: string[] = [];

    constructor() {
        this.reset();
    }

    reset(): void {
        this.logStrings = [];
        this.logStrings.push(`Translator Website Logs for ${Date.now()}`);
        this.logStrings.push(this.newLine());
        this.logStrings.push('====================================================');
        this.logStrings.push(this.newLine());
    }

    trackEvent(eventType: string, props: any | undefined = undefined, logOnlyProps: any | undefined = undefined): void {
        this.logStrings.push(eventType);
        this.logStrings.push(this.newLine());
        if (props != null) {
            for (const key of Object.keys(props)) {
                this.logStrings.push(this.tab());
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                this.logStrings.push(`${key}: ${props[key]}`);
                this.logStrings.push(this.newLine());
            }
        }

        if (logOnlyProps != null) {
            for (const key of Object.keys(logOnlyProps)) {
                this.logStrings.push(this.tab());
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                this.logStrings.push(`${key}: ${logOnlyProps[key]}`);
                this.logStrings.push(this.newLine());
            }
        }
    }

    saveLogFile(): void {
        const blob = new Blob(this.logStrings, {
            type: `text/plain;charset=${window.document.characterSet}`
        });

        FileSaver.saveAs(blob, 'TranslatorWebsiteLogs');
    }

    private tab(count: number = 1): string {
        let tabString = '';
        for (let i = 0; i < count; i++) {
            tabString += '\t';
        }
        return tabString;
    }

    private newLine(count: number = 1): string {
        let newLineString = '';
        for (let i = 0; i < count; i++) {
            newLineString += '\n';
        }
        return newLineString;
    }
}

export const logService = new LogService();
