import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import ParsedUhfContent from './ParsedUhfContent';
import * as Uhf from '../store/Uhf';

type UhfFooterProps = Uhf.UhfState;

class UhfFooter extends React.PureComponent<UhfFooterProps> {
    render(): React.ReactElement {
        if (this.props.uhfData == null) { return <> </> }
        return (
            <div style={{ visibility: (this.props.cssLoaded && this.props.jsLoaded) ? 'visible' : 'hidden' }}>
                <ParsedUhfContent html={this.props.uhfData.footerHtml.value} />
            </div>
        );
    }
};

export default connect(
    (state: ApplicationState) => ({
        uhfData: state.uhf?.uhfData ?? null,
        cssLoaded: state.uhf?.cssLoaded,
        jsLoaded: state.uhf?.jsLoaded
    })
)(UhfFooter as any);
