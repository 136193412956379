import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ApplicationState from '../store';
import * as spx from 'microsoft-cognitiveservices-speech-sdk';
import { TypeOptions } from 'react-toastify';
import { ConversationService } from '../common/services/speechService';
import { v4 as uuidv4 } from 'uuid';
import { localize, usernameDidUpdateFrom, usernameHasBeenUpdated } from '../common/types/common';

export interface ConversationState {
    myParticipantId: string;
    roomCode: string;
    username: string;
    messages: Message[];
    activePartial: Message | undefined;
    notification: Notification | undefined;
    participants: spx.IParticipant[];
    showPartials: boolean;
    mostRecentMessageId: string | undefined;
    conversationService: ConversationService | undefined;
}

export interface IConversationValidation {
    success: boolean;
    error: NotificationType;
}

export interface Notification {
    toastType: TypeOptions;
    notificationType: NotificationType;
    details: string;
}

export enum NotificationType {
    JoinError,
    InvalidUserName,
    InvalidUserNameFormat,
    InvalidRoomCodeFormat,
    NoLocaleSelected,
    InvalidConversationMetadata,
    InvalidLocaleCode,
    ConversationCancelled,
    None
}

export enum MessageType {
    ParticipantJoined,
    ParticipantLeft,
    Partial,
    Final
}

export interface Message {
    participantId: string;
    id: string;
    sourceLanguage: string;
    sourceText: string | undefined;
    targetLanguage: string;
    targetText: string;
    timestamp: string;
    type: MessageType;
    message: string;

    // AJN: note that name maps to whatever the user's name was when the message was received, so this will not account
    // for name changes. Hence, only use this for if a participant has left the conversation. The alternative would be to
    // still hold on to participant entities upon leaving and track some metadata for them having left, but this would
    // get a bit more complex. Let's start here.
    participantName: string;
}

export interface SetConversationService {
    type: 'SET_CONVERSATION_SERVICE';
    conversationService: ConversationService | undefined;
}

export interface ConversationError {
    type: 'CONVERSATION_ERROR';
    notification: Notification;
}

export interface NotificationHandled {
    type: 'NOTIFICATION_HANDLED';
}

export interface ToggleShowPartials {
    type: 'TOGGLE_SHOW_PARTIALS';
}

export interface PartialReceived {
    type: 'PARTIAL_RECEIVED';
    message: Message;
}

export interface FinalReceived {
    type: 'FINAL_RECEIVED';
    message: Message;
}

export interface TextMessageReceived {
    type: 'TEXT_MESSAGE_RECEIVED';
    message: Message;
}

export interface RoomJoined {
    type: 'ROOM_JOINED';
    roomCode: string;
}

export interface RoomLeft {
    type: 'ROOM_LEFT';
}

export interface UsernameUpdated {
    type: 'USERNAME_UPDATED';
    username: string;
}

export interface RoomCodeUpdated {
    type: 'ROOM_CODE_UPDATED';
    roomCode: string;
}

export interface ParticipantsJoined {
    type: 'PARTICIPANTS_JOINED';
    participants: spx.IParticipant[];
}

export interface ParticipantsLeft {
    type: 'PARTICIPANTS_LEFT';
    participants: spx.IParticipant[];
}

export interface ParticipantsUpdated {
    type: 'PARTICIPANTS_UPDATED';
    participants: spx.IParticipant[];
}

export interface ParticipantIdUpdated {
    type: 'PARTICIPANT_ID_UPDATED';
    id: string;
}

export type KnownAction =
    ConversationError |
    NotificationHandled |
    PartialReceived |
    FinalReceived |
    TextMessageReceived |
    RoomJoined |
    RoomLeft |
    UsernameUpdated |
    RoomCodeUpdated |
    ParticipantsJoined |
    ParticipantsLeft |
    ParticipantsUpdated |
    ParticipantIdUpdated |
    ToggleShowPartials |
    SetConversationService;

export const actionCreators = {
    notificationHandled: (): ApplicationState.AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'NOTIFICATION_HANDLED' })
    },
    roomLeft: (): ApplicationState.AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ROOM_LEFT' })
    },
    conversationError: (notification: Notification): ApplicationState.AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'CONVERSATION_ERROR', notification })
    },
    usernameUpdated: (username: string): ApplicationState.AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'USERNAME_UPDATED', username })
    },
    setMyParticipantId: (participantId: string): ApplicationState.AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'PARTICIPANT_ID_UPDATED', id: participantId })
    },
    roomCodeUpdated: (roomCode: string): ApplicationState.AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'ROOM_CODE_UPDATED', roomCode })
    },
    textMessageReceived: (message: Message): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'TEXT_MESSAGE_RECEIVED', message });
    },
    partialReceived: (message: Message): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'PARTIAL_RECEIVED', message });
    },
    finalReceived: (message: Message): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'FINAL_RECEIVED', message });
    },
    participantsJoined: (participants: spx.IParticipant[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'PARTICIPANTS_JOINED', participants });
    },
    participantsLeft: (participants: spx.IParticipant[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'PARTICIPANTS_LEFT', participants });
    },
    participantsUpdated: (participants: spx.IParticipant[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'PARTICIPANTS_UPDATED', participants });
    },
    toggleShowPartials: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'TOGGLE_SHOW_PARTIALS' });
    },
    setConversationService: (conversationService: ConversationService | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_CONVERSATION_SERVICE', conversationService });
    }
};

const unloadedState: ConversationState = {
    roomCode: '',
    username: '',
    messages: [],
    activePartial: undefined,
    notification: undefined,
    participants: [],
    myParticipantId: '',
    showPartials: true,
    mostRecentMessageId: undefined,
    conversationService: undefined
};

export const reducer: Reducer<ConversationState> = (state: ConversationState | undefined, incomingAction: Action): ConversationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    const newMessages = Array.from(state.messages);
    switch (action.type) {
        case 'CONVERSATION_ERROR':
            return {
                roomCode: state.roomCode,
                username: state.username,
                messages: state.messages,
                activePartial: state.activePartial,
                notification: action.notification,
                participants: state.participants,
                myParticipantId: state.myParticipantId,
                showPartials: state.showPartials,
                mostRecentMessageId: state.mostRecentMessageId,
                conversationService: state.conversationService
            };
        case 'NOTIFICATION_HANDLED':
            return {
                roomCode: state.roomCode,
                username: state.username,
                messages: state.messages,
                activePartial: state.activePartial,
                notification: undefined,
                participants: state.participants,
                myParticipantId: state.myParticipantId,
                showPartials: state.showPartials,
                mostRecentMessageId: state.mostRecentMessageId,
                conversationService: state.conversationService
            };
        case 'ROOM_JOINED':
            return {
                roomCode: action.roomCode,
                username: state.username,
                messages: state.messages,
                activePartial: undefined,
                notification: state.notification,
                participants: state.participants,
                myParticipantId: state.myParticipantId,
                showPartials: state.showPartials,
                mostRecentMessageId: state.mostRecentMessageId,
                conversationService: state.conversationService
            };
        case 'ROOM_LEFT':
            return {
                roomCode: state.roomCode,
                username: state.username,
                messages: [],
                activePartial: undefined,
                notification: state.notification,
                participants: [],
                myParticipantId: '',
                showPartials: state.showPartials,
                mostRecentMessageId: undefined,
                conversationService: state.conversationService
            };
        case 'ROOM_CODE_UPDATED':
            return {
                roomCode: action.roomCode,
                username: state.username,
                messages: state.messages,
                activePartial: undefined,
                notification: state.notification,
                participants: state.participants,
                myParticipantId: state.myParticipantId,
                showPartials: state.showPartials,
                mostRecentMessageId: state.mostRecentMessageId,
                conversationService: state.conversationService
            };
        case 'USERNAME_UPDATED':
            return {
                roomCode: state.roomCode,
                username: action.username,
                messages: state.messages,
                activePartial: undefined,
                notification: state.notification,
                participants: state.participants,
                myParticipantId: state.myParticipantId,
                showPartials: state.showPartials,
                mostRecentMessageId: state.mostRecentMessageId,
                conversationService: state.conversationService
            };
        case 'PARTIAL_RECEIVED':
            return {
                roomCode: state.roomCode,
                username: state.username,
                messages: state.messages,
                activePartial: action.message,
                notification: state.notification,
                participants: state.participants,
                myParticipantId: state.myParticipantId,
                showPartials: state.showPartials,
                mostRecentMessageId: action.message.id,
                conversationService: state.conversationService
            };
        case 'FINAL_RECEIVED':
            newMessages.push(action.message);
            return {
                roomCode: state.roomCode,
                username: state.username,
                messages: newMessages,
                activePartial: undefined,
                notification: state.notification,
                participants: state.participants,
                myParticipantId: state.myParticipantId,
                showPartials: state.showPartials,
                mostRecentMessageId: action.message.id,
                conversationService: state.conversationService
            };
        case 'TEXT_MESSAGE_RECEIVED':
            newMessages.push(action.message);
            return {
                roomCode: state.roomCode,
                username: state.username,
                messages: newMessages,
                activePartial: state.activePartial,
                notification: state.notification,
                participants: state.participants,
                myParticipantId: state.myParticipantId,
                showPartials: state.showPartials,
                mostRecentMessageId: action.message.id,
                conversationService: state.conversationService
            };
        case 'PARTICIPANTS_JOINED': {
            const newParticipants = Array.from(state.participants);
            action.participants.forEach((participant: spx.IParticipant) => {
                newParticipants.push(participant);
                // AJN: when participant joins, username is typically set to 'Participant<#>'. This will be updated w/ the correct name
                // in the did update section below, but if a user is joining a meeting that is already existing, the usernames have already
                // been updated, so the joined message here will have the correct names.
                if (usernameHasBeenUpdated(participant)) {
                    newMessages.push({
                        participantId: participant.id,
                        participantName: participant.displayName,
                        id: uuidv4(),
                        sourceLanguage: '',
                        sourceText: '',
                        targetLanguage: '',
                        targetText: '',
                        timestamp: new Date().toUTCString(),
                        type: MessageType.ParticipantJoined,
                        message: `${participant.displayName} ${localize('ARIA_PARTICIPANT_JOINED')}`
                    });
                }
            })

            return {
                roomCode: state.roomCode,
                username: state.username,
                messages: newMessages,
                activePartial: state.activePartial,
                notification: state.notification,
                participants: newParticipants,
                myParticipantId: state.myParticipantId,
                showPartials: state.showPartials,
                mostRecentMessageId: state.mostRecentMessageId,
                conversationService: state.conversationService
            };
        }
        case 'PARTICIPANTS_LEFT': {
            const leftParticipantIds = new Set<string>();
            action.participants.forEach((participant: spx.IParticipant) => {
                leftParticipantIds.add(participant.id);
                newMessages.push({
                    participantId: participant.id,
                    participantName: participant.displayName,
                    id: uuidv4(),
                    sourceLanguage: '',
                    sourceText: '',
                    targetLanguage: '',
                    targetText: '',
                    timestamp: new Date().toUTCString(),
                    type: MessageType.ParticipantLeft,
                    message: `${participant.displayName} ${localize('ARIA_PARTICIPANT_LEFT')}`
                });
            })

            const participantsAfterLeaving: spx.IParticipant[] = [];
            for (let i = 0; i < state.participants.length; i++) {
                const participant = state.participants[i];
                if (!leftParticipantIds.has(participant.id)) {
                    participantsAfterLeaving.push(participant);
                }
            }
            return {
                roomCode: state.roomCode,
                username: state.username,
                messages: newMessages,
                activePartial: state.activePartial,
                notification: state.notification,
                participants: participantsAfterLeaving,
                myParticipantId: state.myParticipantId,
                showPartials: state.showPartials,
                mostRecentMessageId: state.mostRecentMessageId,
                conversationService: state.conversationService
            };
        }
        case 'PARTICIPANTS_UPDATED': {
            const participantsAfterUpdating: spx.IParticipant[] = [];
            for (let i = 0; i < state.participants.length; i++) {
                let participantHasUpdated = false;
                const oldVersionParticipant = state.participants[i];
                for (let j = 0; j < action.participants.length; j++) {
                    const newVersionParticipant = action.participants[j];
                    if (oldVersionParticipant.id === newVersionParticipant.id) {
                        participantHasUpdated = true;
                        participantsAfterUpdating.push(newVersionParticipant);
                        if (usernameDidUpdateFrom(oldVersionParticipant, newVersionParticipant)) {
                            newMessages.push({
                                participantId: newVersionParticipant.id,
                                participantName: newVersionParticipant.displayName,
                                id: uuidv4(),
                                sourceLanguage: '',
                                sourceText: '',
                                targetLanguage: '',
                                targetText: '',
                                timestamp: new Date().toUTCString(),
                                type: MessageType.ParticipantJoined,
                                message: `${newVersionParticipant.displayName} ${localize('ARIA_PARTICIPANT_JOINED')}`
                            });
                        }
                        break;
                    }
                }
                if (!participantHasUpdated) {
                    participantsAfterUpdating.push(oldVersionParticipant);
                }
            }

            return {
                roomCode: state.roomCode,
                username: state.username,
                messages: newMessages,
                activePartial: state.activePartial,
                notification: state.notification,
                participants: participantsAfterUpdating,
                myParticipantId: state.myParticipantId,
                showPartials: state.showPartials,
                mostRecentMessageId: state.mostRecentMessageId,
                conversationService: state.conversationService
            };
        }
        case 'PARTICIPANT_ID_UPDATED':
            return {
                roomCode: state.roomCode,
                username: state.username,
                messages: state.messages,
                activePartial: state.activePartial,
                notification: state.notification,
                participants: state.participants,
                myParticipantId: action.id,
                showPartials: state.showPartials,
                mostRecentMessageId: state.mostRecentMessageId,
                conversationService: state.conversationService
            }
        case 'TOGGLE_SHOW_PARTIALS':
            return {
                roomCode: state.roomCode,
                username: state.username,
                messages: state.messages,
                activePartial: state.activePartial,
                notification: state.notification,
                participants: state.participants,
                myParticipantId: state.myParticipantId,
                showPartials: !state.showPartials,
                mostRecentMessageId: state.mostRecentMessageId,
                conversationService: state.conversationService
            }
        case 'SET_CONVERSATION_SERVICE':
            return {
                roomCode: state.roomCode,
                username: state.username,
                messages: state.messages,
                activePartial: state.activePartial,
                notification: state.notification,
                participants: state.participants,
                myParticipantId: state.myParticipantId,
                showPartials: state.showPartials,
                mostRecentMessageId: state.mostRecentMessageId,
                conversationService: action.conversationService
            }
        default:
            return state;
    }
};
