import baseService from './baseService';
import { API_ROUTES } from '../types/constants';
import { ApplicationState } from '../../store';
import { VersionAction } from '../../store/Version';

export interface IVersionService {
    getVersion: (dispatch: (action: VersionAction) => void, getState: () => ApplicationState) => Promise<void>;
}

interface VersionResponse {
    version: string;
}

class VersionService implements IVersionService {
    public async getVersion(dispatch: (action: VersionAction) => void, getState: () => ApplicationState): Promise<void> {
        const response = await baseService.get<VersionResponse>(API_ROUTES.VERSION);

        dispatch({ type: 'SET_APP_VERSION', version: response.data.version });
    }
}

export const versionService = new VersionService();
