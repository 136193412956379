import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { languagesService } from '../common/services/languagesService';
import { AppsLanguage, TKTranslationVoice } from '../common/types/common';
import { DEFAULTS } from '../common/types/constants';

export interface LanguagesState {
    languages: Map<string, AppsLanguage> | undefined;
    selectedLanguageCode: string;
    selectedLocaleCode: string;
    capitoLocaleCode: string;
    selectedSpeechRegionCode: string | undefined;
    isLoading: boolean;
    startDateIndex: number;
    voice: TKTranslationVoice | undefined;
}

export interface RequestLanguages {
    type: 'REQUEST_LANGUAGES';
    startDateIndex: number;
}

export interface ReceiveLanguages {
    type: 'RECEIVE_LANGUAGES';
    startDateIndex: number;
    languages: Map<string, AppsLanguage>;
}

export interface SetLanguage {
    type: 'SET_LANGUAGE';
    languageCode: string;
    localeCode: string;
}

export interface SetSpeechRegion {
    type: 'SET_SPEECH_REGION';
    regionCode: string | undefined;
}

export interface SetLanguageAndSpeechRegion {
    type: 'SET_LANGUAGE_AND_SPEECH_REGION';
    languageCode: string;
    localeCode: string;
    regionCode: string | undefined;
}

export interface SetVoice {
    type: 'SET_VOICE';
    voice: TKTranslationVoice | undefined;
}

export type KnownAction = RequestLanguages | ReceiveLanguages | SetLanguage | SetVoice | SetSpeechRegion | SetLanguageAndSpeechRegion;

export const actionCreators = {
    requestLanguages: (startDateIndex: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState?.languages != null && startDateIndex !== appState.languages.startDateIndex) {
            void languagesService.getLanguages(startDateIndex, dispatch, getState);
            dispatch({ type: 'REQUEST_LANGUAGES', startDateIndex });
        }
    },
    setLanguage: (languageCode: string, localeCode: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_LANGUAGE', languageCode, localeCode });
    },
    setSpeechRegion: (regionCode: string | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_SPEECH_REGION', regionCode });
    },
    setLanguageAndSpeechRegion: (languageCode: string, localeCode: string, regionCode: string | undefined): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_LANGUAGE_AND_SPEECH_REGION', languageCode, localeCode, regionCode });
    },
    setVoice: (voice: TKTranslationVoice): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_VOICE', voice });
    }
};

const unloadedState: LanguagesState = {
    languages: undefined,
    isLoading: false,
    startDateIndex: -1,
    selectedLanguageCode: DEFAULTS.DEFAULT_LANGUAGE_CODE,
    selectedLocaleCode: DEFAULTS.DEFAULT_LOCALE_CODE,
    selectedSpeechRegionCode: DEFAULTS.DEFAULT_REGION_CODE,
    capitoLocaleCode: DEFAULTS.DEFAULT_CAPITO_LOCALE_CODE,
    voice: undefined
};

export const reducer: Reducer<LanguagesState> = (state: LanguagesState | undefined, incomingAction: Action): LanguagesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_LANGUAGES':
            return {
                startDateIndex: action.startDateIndex,
                languages: state.languages,
                selectedLanguageCode: state.selectedLanguageCode,
                selectedLocaleCode: state.selectedLocaleCode,
                selectedSpeechRegionCode: state.selectedSpeechRegionCode,
                capitoLocaleCode: state.capitoLocaleCode,
                isLoading: true,
                voice: state.voice
            };
        case 'RECEIVE_LANGUAGES':
            if (action.startDateIndex === state.startDateIndex) {
                return {
                    startDateIndex: action.startDateIndex,
                    languages: action.languages,
                    selectedLanguageCode: state.selectedLanguageCode,
                    selectedLocaleCode: state.selectedLocaleCode,
                    selectedSpeechRegionCode: state.selectedSpeechRegionCode,
                    capitoLocaleCode: state.capitoLocaleCode,
                    isLoading: false,
                    voice: state.voice
                };
            }
            return state;
        case 'SET_VOICE':
            return {
                languages: state.languages,
                startDateIndex: state.startDateIndex,
                isLoading: state.isLoading,
                selectedLanguageCode: state.selectedLanguageCode,
                selectedLocaleCode: state.selectedLocaleCode,
                selectedSpeechRegionCode: state.selectedSpeechRegionCode,
                capitoLocaleCode: state.capitoLocaleCode,
                voice: action.voice
            };
        case 'SET_LANGUAGE_AND_SPEECH_REGION': {
            // AJN: on language selection, reset voice parameter so we don't have a stale voice from a prior language.
            // We don't do this greedily, though, so that a user's voice selection can persist across conversations
            let newVoice;
            if (action.languageCode === state.selectedLanguageCode && action.localeCode === state.selectedLocaleCode) {
                newVoice = state.voice;
            }

            const capitoLocaleCode = action.regionCode != null
                ? `${action.languageCode}-${action.regionCode}`
                : action.localeCode;

            return {
                languages: state.languages,
                startDateIndex: state.startDateIndex,
                isLoading: state.isLoading,
                selectedLanguageCode: action.languageCode,
                selectedLocaleCode: action.localeCode,
                selectedSpeechRegionCode: action.regionCode,
                capitoLocaleCode,
                voice: newVoice
            };
        }
        default:
            return state;
    }
};
