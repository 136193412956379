import { t } from 'i18next';
import * as spx from 'microsoft-cognitiveservices-speech-sdk';

export function localize(value: string): string {
    // eslint wrong on this one
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    return t(value) as string;
}

export interface LanguagesResponse {
    languages: Map<string, AppsLanguage>;
    etag: string;
    locale: string;
}

export interface TokenResponse {
    token: string;
    region: string;
    expiresIn: number;
}

export interface TtsResponse {
    // AJN: pass audioData as base64 encoded string
    audioData: string;
    audioDuration: number;
    resultId: string;
    cancellationDetails: string;
    reason: string;
}

export interface TKLanguageList {
    languages: Map<string, AppsLanguage>;
}

export interface AppsLanguage {
    name: string;
    locales: Map<string, TKLocale>;
    speechLocales: TKTranslationRegion[];
    voices: TKTranslationVoice[];
}

export interface TKLocale {
    features: TKLocaleFeatures;
    metadata: TKLocaleMetadata;
}

export interface TKLocaleFeatures {
    text: boolean;
    speech: boolean;
    image: boolean;
    offlineImage: boolean;
    offlineText: boolean;
    conversation: boolean;
    groupTranscribe: boolean;
    autoMode: boolean;
    sourceTranslation: boolean;
    targetTranslation: boolean;
}

export interface TKLocaleMetadata {
    name: string;
    nativeName: string;
    dir: string;
    scripts: TKTranslationScript[];
    defaultSpeechCode: string;
    dictionaryLanguageCodes: string[];
}

export interface TKTranslationRegion {
    code: string;
    name: string;
    nativeName: string;
}

export interface TKTranslationVoice {
    displayName: string;
    shortName: string;
    locale: string;
    regionCode: string;
    regionName: string;
    nativeRegionName: string;
    gender: string;
}

export interface TKTranslationScript {
    code: string;
    name: string;
    nativeName: string;
    dir: string;
    toScriptCodes: string[];
}

export function sortedLanguageEntries(map: Map<string, AppsLanguage>): Array<[string, AppsLanguage]> {
    return [...map.entries()].sort((one, two) => sort(one[1].name, two[1].name));
}

export function sortedLocaleEntries(map: Map<string, TKLocale>): Array<[string, TKLocale]> {
    return [...map.entries()].sort((one, two) => sort(one[1].metadata.name, two[1].metadata.name));
}

export function speechAndTextLanguages(languages: Map<string, AppsLanguage>): Array<[string, TKLocale]> {
    const locales: Array<[string, TKLocale]> = [];
    [...sortedLanguageEntries(languages)].forEach((languageEntry: [string, AppsLanguage]) => {
        [...languageEntry[1].locales.entries()].forEach((localeEntry: [string, TKLocale]) => {
            const locale = localeEntry[1];
            if (locale.features.speech && locale.features.text) {
                locales.push(localeEntry);
            }
        });
    });

    return locales;
}

export function textOnlyLanguages(languages: Map<string, AppsLanguage>): Array<[string, TKLocale]> {
    const locales: Array<[string, TKLocale]> = [];
    [...sortedLanguageEntries(languages)].forEach((languageEntry: [string, AppsLanguage]) => {
        [...languageEntry[1].locales.entries()].forEach((localeEntry: [string, TKLocale]) => {
            const locale = localeEntry[1];
            if (!locale.features.speech && locale.features.text) {
                locales.push(localeEntry);
            }
        });
    });

    return locales;
}

export function sort(one: string, two: string): number {
    if (one > two) {
        return 1;
    } else if (one < two) {
        return -1;
    } else {
        return 0;
    }
}

export function getForcedColorsState(): boolean {
    const isSupported = window.matchMedia(
        'not all and (forced-colors: active), (forced-colors: active)'
    ).matches;

    const isEnabled = window.matchMedia('(forced-colors: active)').matches;

    return isSupported && isEnabled;
}

export function usernameHasBeenUpdated(participant: spx.IParticipant): boolean {
    return !participant.displayName.toLowerCase().startsWith('participant');
}

export function usernameDidUpdateFrom(participantOne: spx.IParticipant, participantTwo: spx.IParticipant): boolean {
    if (participantOne.id !== participantTwo.id) {
        throw new Error("participant ID's must match to compare if username has been updated");
    }
    return !usernameHasBeenUpdated(participantOne) && usernameHasBeenUpdated(participantTwo);
}
