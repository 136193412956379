import axios from 'axios';

/**
 * Define the frontend api.
 * TODO: merge the client APIs once the flows are completed.
 */
const baseService = axios.create({
    baseURL: '/',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

// baseService.defaults.headers.common['x-xsfr-token'] = getToken();

/**
 * Use interceptors for the response.
 */
baseService.interceptors.response.use(
    (response: any) => {
        return response;
    },
    async (error: any) => {
        if (error.response == null) {
            // network error
            return await Promise.reject(error);
        } else {
            return await Promise.reject(error.response);
        }
    }
);

export default baseService;
