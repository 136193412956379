import { t } from 'i18next';
import type * as spx from 'microsoft-cognitiveservices-speech-sdk';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class ConversationUtils {
    static participantNameForId(participants: spx.IParticipant[], id: string): string {
        if (participants == null || participants.length === 0) {
            return t('NO_KNOWN_PARTICIPANTS');
        }

        for (let i = 0; i < participants.length; i++) {
            const participant = participants[i];
            if (participant.id === id) {
                return participant.displayName;
            }
        }

        return t('UNKNOWN_SENDER');
    }

    // AJN: even though we join a meeting w/ zh-HK, capito returns translations keyed under the alternative, "yue".
    // There may be more such special cases in the future - if so, add them here.
    static resultLocaleCodeFor(capitoLocaleCode: string | undefined): string | undefined {
        if (capitoLocaleCode == null) { return undefined; }

        const lowerCasedCapitoLocaleCode = capitoLocaleCode.toLowerCase();
        switch (lowerCasedCapitoLocaleCode) {
            case 'zh-cn':
                return 'zh-Hans';
            case 'zh-tw':
                return 'zh-Hant';
            case 'zh-hk':
                return 'yue';
            default:
                return capitoLocaleCode;
        }
    }

    static areSameLanguage(codeOne: string, codeTwo: string): boolean {
        const languageCodeOne = this.languageCodeFromLocaleCode(codeOne);
        const languageCodeTwo = this.languageCodeFromLocaleCode(codeTwo);

        return languageCodeOne === languageCodeTwo;
    }

    static specialCodes: Set<string> = new Set<string>(['zh-hans', 'zh-hant', 'iu-latn', 'mn-cyrl', 'mn-mong', 'sr-cyrl', 'sr-latn', 'thl-latn', 'thl-piqd']);
    static languageCodeFromLocaleCode(code: string): string {
        const lowerCasedCode = code.toLowerCase();
        if (this.specialCodes.has(lowerCasedCode)) {
            return code;
        }

        return code.split('-')[0].toLowerCase();
    }
}
