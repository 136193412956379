import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { uhfService } from '../common/services/uhfService';
import { ShellModel } from '../common/models/shellModel';

export interface UhfState {
    uhfData: ShellModel | null;
    cssLoaded: boolean;
    jsLoaded: boolean;
}

export interface SetUhfData {
    type: 'SET_UHF_DATA';
    uhfData: ShellModel;
}

export interface SetJsLoaded {
    type: 'SET_JS_LOADED';
}

export interface SetCssLoaded {
    type: 'SET_CSS_LOADED';
}

export type UhfAction = SetUhfData | SetJsLoaded | SetCssLoaded;

export const actionCreators = {
    getUhf: (locale: string): AppThunkAction<UhfAction> => (dispatch, getState) => {
        void uhfService.getUhf(locale, dispatch, getState);
    },
    setCssLoaded: (): AppThunkAction<UhfAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_CSS_LOADED' });
    },
    setJsLoaded: (): AppThunkAction<UhfAction> => (dispatch, getState) => {
        dispatch({ type: 'SET_JS_LOADED' });
    }
};

const unloaded: UhfState = { uhfData: null, jsLoaded: false, cssLoaded: false };

export const reducer: Reducer<UhfState> = (state: UhfState | undefined, incomingAction: Action): UhfState => {
    if (state === undefined) {
        return unloaded;
    }

    const action = incomingAction as UhfAction;
    switch (action.type) {
        case 'SET_UHF_DATA':
            return {
                uhfData: action.uhfData,
                cssLoaded: state.cssLoaded,
                jsLoaded: state.jsLoaded
            };
        case 'SET_CSS_LOADED':
            return {
                uhfData: state.uhfData,
                cssLoaded: true,
                jsLoaded: state.jsLoaded
            };
        case 'SET_JS_LOADED':
            return {
                uhfData: state.uhfData,
                cssLoaded: state.cssLoaded,
                jsLoaded: true
            };
        default:
            return state;
    }
};
