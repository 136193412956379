import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import ParsedUhfContent from './ParsedUhfContent';
import * as Uhf from '../store/Uhf';

type UhfHeaderProps =
    Uhf.UhfState
    & typeof Uhf.actionCreators;

class UhfHeader extends React.PureComponent<UhfHeaderProps> {
    private _domParser: DOMParser | null = null;

    parser(): DOMParser {
        if (this._domParser == null) {
            this._domParser = new DOMParser();
        }

        return this._domParser;
    }

    render(): React.ReactElement {
        if (this.props.uhfData == null) { return <> </> }

        if (this.props.uhfData.javaScriptIncludes?.value != null) {
            const scriptTags = this.parser().parseFromString(this.props.uhfData.javaScriptIncludes.value, 'text/html').getElementsByTagName('script');

            for (let i = 0; i < scriptTags.length; i++) {
                const scriptTag = scriptTags[i];
                const script = document.createElement('script');

                if (scriptTag.src != null) {
                    script.src = scriptTag.src;
                } else {
                    script.innerHTML = scriptTag.innerHTML;
                }
                if (scriptTag.hasAttribute('defer')) {
                    script.defer = true;
                }

                script.onload = () => {
                    this.props.setJsLoaded();
                }

                document.head.appendChild(script);
            }
        }

        if (this.props.uhfData.cssIncludes?.value != null && this.props.uhfData.cssIncludes?.value !== '') {
            const linkTags = this.parser().parseFromString(this.props.uhfData.cssIncludes?.value, 'text/html').getElementsByTagName('link');
            if (linkTags != null && linkTags.length > 0) {
                const link = linkTags[0];
                link.onload = () => {
                    this.props.setCssLoaded();
                };

                document.head.appendChild(link);
            }
        }

        return (
            <div style={{ visibility: (this.props.cssLoaded && this.props.jsLoaded) ? 'visible' : 'hidden' }}>
                <ParsedUhfContent html={this.props.uhfData.headerHtml.value} />
                <style>{ParsedUhfContent({ html: this.props.uhfData.cssIncludes.value })}</style>
            </div>
        );
    }
};

export default connect(
    (state: ApplicationState) => ({
        uhfData: state.uhf?.uhfData ?? null,
        cssLoaded: state.uhf?.cssLoaded,
        jsLoaded: state.uhf?.jsLoaded
    }),
    ({
        ...Uhf.actionCreators
    })
)(UhfHeader as any);
